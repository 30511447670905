<template>
    <div class="examData-wrapper">
        <div class="top-tab" v-if="opId">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="订单管理" name="studentTrainCenterOnlineShopManagementsAllOrder"></el-tab-pane>
                <!-- <el-tab-pane label="待发货" name="studentTrainCenterOnlineShopManagementDelivered"></el-tab-pane>
                <el-tab-pane label="已发货" name="studentTrainCenterOnlineShopManagementDeliverGoods"></el-tab-pane>
                <el-tab-pane label="售后订单" name="studentTrainCenterOnlineShopManagementSalesOrder"></el-tab-pane> -->
            </el-tabs>
        </div>
        <div class="top-tab" v-else>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="订单管理" name="studentExamingOnlineShopManagementsAllOrder"></el-tab-pane>
                <el-tab-pane v-if="exam_module_id==91" label="评价管理"  name="studentExamingOnlineShopManagementsEvaluateMange"></el-tab-pane>
                <!-- <el-tab-pane label="待发货" name="studentExamingOnlineShopManagementDelivered"></el-tab-pane>
                <el-tab-pane label="已发货" name="studentExamingOnlineShopManagementDeliverGoods"></el-tab-pane>
                <el-tab-pane label="售后订单" name="studentExamingOnlineShopManagementSalesOrder"></el-tab-pane> -->
            </el-tabs>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import { stuOpGetExamInfo } from '@/utils/apis.js'
    export default {
        name: "GoodsShopLayout",
        data() {
            return {
                examModuleId: Number(this.$route.query.exam_module_id) || '',
                opId: Number(this.$route.query.id) || '',
                // orderId: Number(this.$route.query.order_id) || '',
                tabName: '',
                exam_module_id:''

            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
        },
        mounted() {
            if(this.$route.query.exam_module_id){
                this.exam_module_id=this.$route.query.exam_module_id
            }
            

        },
        methods: {
            //切换
            toggleTab(tab) {
                if (this.opId) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            id: this.opId,
                            exam_module_id:this.examModuleId,
                            course_id: this.$route.query.course_id,
                            // order_id:this.orderId
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            exam_module_id:this.examModuleId,
                            // order_id:this.orderId
                        }
                    })
                }
            },
            //下载素材
            downloadMaterial() {
                let param = {
                    type: 'shop_management'
                }
                if (this.opId) {
                    param.op_id = this.opId;
                }
                stuOpGetExamInfo(param).then((res) => {
                    window.location.href = res.data.file_download;
                }).catch((err) => {
                    console.log(err);
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>