<template>
    <el-scrollbar class="info-step">
        <div class="info-step-header">
            <el-breadcrumb>
                <el-breadcrumb-item>展位</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
                <el-breadcrumb-item>新建计划</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="info-step-box">
            <div class="info-step-left">
                <div class="left-creation">
                    <p :class="stepActive >= 1 ? 'isActive' : ''">01 <span>创建计划</span></p>
                    <div class="left-step">
                        <div class="stepOne" :class="stepActive >= 1 ? 'isActive' : ''">
                            <span class="stepActive"></span>
                            <span>推广首页</span>
                        </div>
                        <div :class="stepActive > 1 ? 'stepXian xianActive' : 'stepXian'">
                            <span v-show="stepActive > 1">店铺推广</span>
                            <span v-show="stepActive > 1 && stepActive < 4" @click="setOne">修改</span>
                        </div>
                        <div class="stepOne" :class="stepActive >= 2 ? 'isActive' : ''">
                            <span :class="stepActive > 1 ? 'stepActive' : ''"></span>
                            <span>计划设置</span>
                        </div>
                        <div v-show="stepActive > 2" class="stepXian" style="border: none; padding-bottom: 0">
                            <span>统一日预算：{{promoteInfo.budget}}</span>
                            <span v-show="stepActive < 4" @click="setTwo">修改</span>
                        </div>
                    </div>
                    <div class="left-stepTwo">
                        <p style="padding-top: 40px" :class="stepActive >= 3 ? 'isActive' : ''">02 <span>创建单元</span></p>
                        <div class="left-step">
                            <div class="stepOne" :class="stepActive >= 3 ? 'isActive' : ''">
                                <span :class="stepActive > 2 ? 'stepActive' : ''"></span>
                                <span>出价设置</span>
                            </div>
                            <div :class="stepActive > 2 ? 'stepXian xianActive' : 'stepXian'">
                                <span v-show="stepActive > 3">通投出价：{{promoteInfo.cast_fee}}</span>
                            </div>
                            <div class="stepOne" :class="stepActive >= 3 ? 'isActive' : ''">
                                <span :class="stepActive >2 ? 'stepActive' : ''"></span>
                                <span>资源位</span>
                            </div>
                            <div :class="stepActive > 2 ? 'stepXian xianActive' : 'stepXian'">
                                <span v-show="stepActive > 3">{{promoteInfo.resource}}个资源位</span>
                            </div>
                            <div class="stepOne" :class="stepActive >= 3 ? 'isActive' : ''">
                                <span :class="stepActive > 2 ? 'stepActive' : ''"></span>
                                <span>定向设置</span>
                            </div>
                            <div v-show="stepActive > 3" class="stepXian" style="border: none; padding-bottom: 0">
                                <span>{{promoteInfo.targetedPopulation}}个定向人群</span>
                            </div>
                        </div>
                    </div>
                    <div class="left-succeed">
                        <p style="padding-top: 40px" :class="stepActive >= 4 ? 'isActive' : ''">03 <span>完成推广</span></p>
                    </div>
                </div>
            </div>
            <div class="info-step-right">
                <keep-alive>
                    <router-view />
                </keep-alive>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { StuOpProInfo } from '@/utils/apis.js'
    export default {
        data(){
            return {
                stepActive: 1,
                promoteInfo: {
                    budget: '不限', // 统计日预算
                    cast_fee: 0, // 通投出价
                    resource: 0, // 资源位
                    targetedPopulation: 0, // 定向人群
                },
            }
        },
        created() {
            this.refreshPageRoute();
        },
        watch: {
            $route(to){
                if (to.name === 'infoStepOne') {
                    this.stepActive = 1;
                } else if (to.name === 'infoStepTwo') {
                    this.stepActive = 2
                } else if (to.name === 'infoStepThree') {
                    this.stepActive = 3
                } else if (to.name === 'infoStepFour') {
                    this.stepActive = 4
                }
                if (localStorage.getItem('infoStepTwo')) {
                    let infoStepTwo = JSON.parse(localStorage.getItem('infoStepTwo'))
                    if (Number(infoStepTwo.budget)) {
                        this.promoteInfo.budget = infoStepTwo.budget
                    } else {
                        this.promoteInfo.budget = '不限'
                    }
                }
            },
            stepActive(val) {
                if (val === 4) {
                    if (this.$route.query.editId) {
                        this.getInfo()
                    }
                }
            }
        },
        methods: {
            getInfo() {
                StuOpProInfo(this.$route.query.editId).then((res) => {
                    let peopleArr = res.data.people_data.filter(item => {
                        return item.switchPeople
                    })
                    this.promoteInfo = {
                        budget: Number(res.data.budget) === 0 ? '不限' : Number(res.data.budget),
                        cast_fee: res.data.cast_fee,
                        resource: res.data.resources_data.length,
                        targetedPopulation: peopleArr.length,
                    }
                })
            },
            refreshPageRoute(){
                if (this.$route.name === 'infoStepOne') {
                    this.stepActive = 1;
                } else if (this.$route.name === 'infoStepTwo') {
                    this.stepActive = 2
                } else if (this.$route.name === 'infoStepThree') {
                    this.stepActive = 3
                } else if (this.$route.name === 'infoStepFour') {
                    this.stepActive = 4
                }
            },
            setOne() {
                if(this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                        }
                    })
                }
            },
            setTwo() {
                if(this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepTwo',
                        query:{
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId:this.$route.query.editId
                        }
                    })
                } else {
                    this.$router.go(-1)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .stepActive {
        background: #20D08C;
        border-color: #20D08C!important;
    }
    .xianActive {
        border-color: #20D08C!important;
    }
    .info-step {
        height: 100%;
        background: #F7F9FB;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .info-step-header {
            padding: 22px 0 0 40px
        }
        .info-step-box {
            display: flex;
            .info-step-left {
                width: 190px;
                margin-left: 40px;
                .left-creation {
                    display: flex;
                    flex-direction: column;
                    p:nth-child(1) {
                        margin: 0;
                        padding: 20px 0;
                        line-height:1;
                        /*color: #333333;*/
                        color: #959697;
                        font-size: 48px;
                        span {
                            font-size: 24px;
                        }
                        &.isActive {
                            color: #333;
                        }
                    }
                    .left-step {
                        .stepOne {
                            line-height: 0;
                            display: flex;
                            align-items: center;
                            padding-left: 10px;
                            color: #959697;
                            span {
                                display: inline-block;
                            }
                            span:nth-child(1) {
                                width: 14px;
                                height: 14px;
                                border: 1px solid #DFDFDF;
                                border-radius: 50%;
                            }
                            span:nth-child(2) {
                                margin-left: 16px;
                                font-size: 20px;
                            }
                            &.isActive {
                                color: #333;
                            }
                        }
                        .stepXian {
                            border-left: 1px solid #DFDFDF;
                            margin-left: 17px;
                            line-height: 1;
                            padding-left: 23px;
                            padding-top: 10px;
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 30px;
                            span:nth-child(1) {
                                color: #333333;
                                font-size: 14px;
                            }
                            span:nth-child(2) {
                                cursor: pointer;
                                margin-top: 6px;
                                color: #1E63F1;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .info-step-right{
                width: 1%;
                flex: 1;
                margin: 20px 30px 50px 0;
            }
        }
    }
</style>