<template>
    <div class="student-container">
        <div class="left-wrapper">
            <div class="left-header">
                <div class="logo">
                    <i class="iconfont">&#xe63c;</i>
                </div>
                <p class="theme">运营推广</p>
            </div>
            <el-scrollbar class="left-menu-wrapper" :native="false">
                <el-menu
                        :default-active="menuActive"
                        class="el-menu-vertical-demo left-menu"
                        @open="handleOpen"
                        @close="handleClose">
                    <el-menu-item index="operationPromotionIndex">
                        <router-link to="/student/operationPromotion/index" class="nav-item"><i class="iconfont">&#xe69a;</i>搜索广告
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="operationPromotionDataAnalysis">
                        <router-link to="/student/operationPromotion/dataAnalysis" class="nav-item" target="_blank">
                            <i class="iconfont">&#xe63c;</i>
                            数据分析
                        </router-link>
                    </el-menu-item>
                </el-menu>
            </el-scrollbar>
        </div>
        <div class="right-wrapper">
            <div class="right-header">
                <div class="countdown">
                    <!--倒计时-->
                    <!--                    <CountDown></CountDown>-->
                </div>
                <div class="user-wrapper">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('@/assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="backTo">
                        <i class="iconfont">&#xe72e;</i>
                        <span>返回主界面</span>
                    </div>
                </div>
            </div>
            <el-scrollbar class="right-content" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="608px">
                <div class="train-dialog-content">
                    <span>1. 点击确定提交答案并返回首页，您可以重新进来编辑提交，成绩以最后提交为准</span>
                    <span>2. 点击取消继续留在当前页面作答</span>
                    <span>3. <b style="color: red">*注意：</b>运营推广-搜索广告-推广管理：创建好的推广计划，要先点击结束推广，才算完成答题，可以重新编辑后重新结束推广</span>
                </div>
                <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="endPromotion" class="btn-red">确 定</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    // import CountDown from '../../../components/student/CountDown.vue'
    export default {
        name: "TrainAdminLayout",
        data() {
            return {
                menuActive: 'operationPromotionIndex',
                username: '',
                userIcon: '',
                downFile: null,
                dialogVisible: false
            }
        },
        // components: {
        //     CountDown
        // },
        watch: {
            $route(route) {
                this.setActiveTab(route.meta.parentName);
            },
        },
        mounted() {
            //获取运营菜单列表
            this.username = localStorage.getItem('studentName');
            let userIcon = localStorage.getItem('studentAvatar');
            if (userIcon !== null && userIcon.length > 0) {
                this.userIcon = userIcon;
            }
            // this.menuActive = this.$route.name;
        },
        methods: {
            //设置路由
            setActiveTab(routeName) {
                if (routeName && routeName.includes('operationPromotionIndex')) {
                    this.menuActive = 'operationPromotionIndex'
                }
                // else {
                //     this.menuActive = 'operationPromotionDataAnalysis'
                // }
            },
            // 返回主界面
            backTo() {
                // this.$router.push('/student/index');
                this.dialogVisible = true;
            },
            //点击返回主界面时，选择要不要结束推广，确定时调用
            endPromotion() {
                let formData = new FormData();
                window.close();
            },
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            },
            //结束推广
            deleSelectPromotion() {
                this.$confirm('确定要结束推广么？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消结束'
                    });
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;

        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;

            .left-header {
                text-align: center;

                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;

                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }

                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }

            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .left-menu {
                    height: 100%;
                    background: transparent;
                    border-right: none;
                }

                .el-menu-item {
                    height: 60px;
                    line-height: 60px;
                    padding: 0 !important;

                    a.nav-item {
                        height: 100%;
                        color: #fff;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        position: relative;
                        transition: all .3s;

                        .iconfont {
                            color: #F7F9FB;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                    }

                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }

                    /*&.is-active, &.is-opened {*/
                    &.is-active {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 2px;
                            height: 100%;
                            background: #FF4400;
                        }
                    }
                }

                .el-submenu {
                    height: auto;
                    line-height: 60px;
                    padding: 0 !important;

                    ::v-deep .el-submenu__title {
                        height: 100%;
                        color: #F7F9FB;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px !important;
                        position: relative;
                        transition: all .3s;

                        .iconfont {
                            color: #F7F9FB;
                            font-size: 20px;
                            margin-right: 20px;
                            transition: all .3s;
                        }

                        a {
                            color: #fff;
                        }

                        ::v-deep .el-submenu__icon-arrow {
                            color: #fff;
                            transform: rotateZ(270deg);
                        }

                        &:focus, &:hover, &.is-active {
                            background-color: #25354F;
                        }

                        &:focus {
                            &:before {
                                content: '';
                                position: absolute;
                                left: 0;
                                width: 2px;
                                height: 100%;
                                background: #FF4400;
                            }
                        }
                    }

                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }

                    /*&.is-active, &.is-opened {*/
                    /*&:before {*/
                    /*content: '';*/
                    /*position: absolute;*/
                    /*left: 0;*/
                    /*width: 2px;*/
                    /*height: 100%;*/
                    /*background: #FF4400;*/
                    /*}*/
                    /*}*/
                    ::v-deep .el-menu {
                        background: #2C405F;

                        .el-menu-item {
                            height: 60px;
                            line-height: 60px;
                            padding: 0 !important;

                            .nav-item {
                                height: 100%;
                                color: #F7F9FB;
                                font-size: 18px;
                                display: flex;
                                align-items: center;
                                padding: 0 20px 0 40px;
                                position: relative;
                                transition: all .3s;

                                a {
                                    display: block;
                                    width: 100%;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }

        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;

            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .header-tab {
                    height: 100%;
                    position: absolute;
                    left: 30px;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    color: #333333;

                    .activeSelect {
                        a {
                            display: block;
                            height: 100%;
                            color: #FD4446 !important;
                            border-bottom: 2px solid #FD4446 !important;
                        }
                    }

                    .item-tab {
                        height: 100%;
                        margin-right: 25px;
                        cursor: pointer;
                        line-height: 81px;
                        border-bottom: 2px solid transparent;
                        font-size: 20px;

                        a {
                            color: #333333;
                            display: block;

                            &:hover {
                                color: #FD4446;
                            }
                        }
                    }
                }

                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;

                    .time {
                        margin-left: 10px;
                    }
                }

                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;

                    .account-balance {
                        display: flex;
                        align-items: center;
                        color: #FD4446;
                        font-weight: bold;
                        margin-right: 24px;
                    }

                    .user-info {
                        display: flex;
                        align-items: center;

                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        .user-name {
                            margin-left: 6px;
                        }
                    }

                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;

                        .iconfont {
                            font-size: 20px;
                        }

                        span {
                            margin-left: 6px;
                        }

                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }

            ::v-deep .right-content {
                height: 1%;
                flex: 1;

                .el-scrollbar__wrap {
                    overflow-x: hidden;

                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }

                    .right-wrapper-content {
                        padding: 20px 30px 20px 40px;
                        background: #F7F9FB;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }

    ::v-deep p {
        margin: 0;
    }

    .endPromotion {
        padding: 0 20px 0 42px;
        color: #ffffff;
        font-size: 17px;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        transition: 0.5s;

        i {
            font-size: 18px;
        }

        span {
            margin-left: 20px;
        }
    }

    .endPromotion:hover {
        background: #25354F;
    }

    .train-dialog-content {
        display: flex;
        flex-direction: column;
        line-height: 2;
    }
</style>
