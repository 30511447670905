<template>
    <div class="examData-wrapper">
<!--        <div class="top-tab" v-if="op_id">-->
<!--            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">-->
<!--                <el-tab-pane label="新增粉丝" name="studentTrainCenterECommerceDataCollectFansAdd"></el-tab-pane>-->
<!--                <el-tab-pane label="成交粉丝" name="studentTrainCenterECommerceDataCollectFansDeal"></el-tab-pane>-->
<!--                <el-tab-pane label="全部粉丝" name="studentTrainCenterECommerceDataCollectFansAll"></el-tab-pane>-->
<!--            </el-tabs>-->
<!--        </div>-->
<!--        <div class="top-tab" v-else>-->
        <div class="top-tab">
            <template v-if="exam_grade === 1">
                <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                    <el-tab-pane label="店招" name="studentExamingOnlineShopDecorationPageDZ"></el-tab-pane>
                    <el-tab-pane label="电脑海报" name="studentExamingOnlineShopDecorationPageSetBanner"></el-tab-pane>
                </el-tabs>
            </template>
            <template v-else>
                <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                    <el-tab-pane label="店招" name="studentExamingOnlineShopVisualDesignPageDZ"></el-tab-pane>
                    <el-tab-pane label="电脑海报" name="studentExamingOnlineShopVisualDesignPageSetBanner"></el-tab-pane>
                    <el-tab-pane label="店铺分类" name="studentExamingOnlineShopVisualDesignPageSetClass"></el-tab-pane>
                    <el-tab-pane label="商品列表" name="studentExamingOnlineShopVisualDesignPageSetGood"></el-tab-pane>
                </el-tabs>
            </template>
            <div class="downLoad">
                <el-button type="primary" @click="downLoad">下载素材</el-button>
            </div>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                hostUrl:localStorage.getItem('hosturl') || '',
                examModuleId: Number(this.$route.query.moduleId) || '',
                id: Number(this.$route.query.id) || '', // 实操op_id
                // op_id: Number(this.$route.query.op_id) || '',
                tabName: '',
                exam_grade: Number(localStorage.getItem('exam_grade')) || ''
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
        },
        mounted() {

        },
        methods: {
            //切换
            toggleTab(tab) {
                // if (this.op_id) {
                //     this.$router.push({
                //         name: tab.name,
                //         query: {
                //             op_id: this.op_id,
                //             moduleId:this.examModuleId,
                //             course_id: this.$route.query.course_id,
                //             chapter: this.$route.query.chapter,
                //             node: this.$route.query.node,
                //         }
                //     })
                // } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            id:this.id,
                            moduleId:this.examModuleId,
                        }
                    })
                // }
            },
            downLoad() {
                window.open(this.hostUrl + `stuOp/download?id=${this.id}`)
            }
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>