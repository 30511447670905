<template>
    <div class="examData-wrapper">
        <div class="top-tab" v-if="opId">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="发货地址管理" name="studentTrainCenterStartTrainingDeliveryAddress"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41"  label="备件库退货地址管理"  name="studentTrainCenterStartTrainingStudentSpareParts"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41"  label="自主售后退货地址管理"  name="studentTrainCenterStartTrainingStudentAfterSales"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41"  label="运费模板"  name="studentTrainCenterStartTrainingStudentTemplateModel"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===40" label="在售商品管理" name="studentTrainCenterStartTrainingStudentSellingGoods"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===40" label="成交客户分析" name="studentTrainCenterStartTrainingStudentCustomerTran"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="top-tab" v-else>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="发货地址管理" name="studentExamOnlineTradeManageDeliveryAddress"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41 && onlineStatus == ''" label="备件库退货地址管理"  name="studentExamOnlineTradeManageStudentSpareParts"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41 && onlineStatus != ''" label="备件库退货地址管理"  name="studentExamOnlineTradeManageStudentSparePartsList"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41" label="自主售后退货地址管理"   name="studentExamOnlineTradeManageStudentAfterSales"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===41" label="运费模板"  name="studentExamOnlineTradeManageStudentTemplateModel"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===40" label="在售商品管理" name="studentExamOnlineTradeManageStudentSellingGoods"></el-tab-pane>
                <el-tab-pane v-if="examModuleId===40" label="成交客户分析" name="studentExamOnlineTradeManageStudentCustomerTran"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    export default {
        name: "GoodsShopLayout",
        data() {
            return {
                examModuleId: Number(this.$route.query.exam_module_id) || '',
                opId: Number(this.$route.query.op_id) || '',
                course_id: Number(this.$route.query.course_id) || '',
                tabName: '',
                onlineStatus: ''
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
            this.onlineStatus = localStorage.getItem('onlineStatus');
        },
        mounted() {
            
        },
        methods: {
            //切换
            toggleTab(tab) {
                if (this.opId) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            op_id: this.opId,
                            exam_module_id:this.examModuleId,
                            course_id:this.course_id,
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            exam_module_id:this.examModuleId,
                            onlineStatus: this.onlineStatus
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>