<template>
    <div class="student-index-container">
        <div class="course-left" v-if="leftVal == 1">
            <el-select v-model="select_course" placeholder="请选择课程" class="select-course"
                       @change="chooseCourse">
                <el-option v-for="item in studentCourseList" :key="item.course_id" :label="item.course_name"
                           :value="item.course_id"></el-option>
            </el-select>
            <div class="item-title" :class="isChosen === true?'choseAll':''" @click="choseAll">
                <span class="text">课程总览</span>
            </div>
            <el-scrollbar class="zhang-jie" :native="false">
                <div class="zhang-jie-item" :class="setClass(zhangIndex)"
                     v-for="(zhangItem, zhangIndex) in courseZhangJie" :key="zhangIndex">
                    <div class="zhang"
                         :title="`${zhangItem.name}`">
                        <span class="text">{{zhangItem.name}}</span>
                    </div>
                    <div class="jie-box">
                        <div class="jie-inside-box">
                            <div class="jie"
                                 :class="{current: currentJie === jieIndex && chapterIndex == zhangIndex}"
                                 v-for="(jieItem, jieIndex) in zhangItem.child"
                                 @click="clickJie(zhangIndex,jieIndex)"
                                 :title="`0${jieIndex+1} | ${jieItem.name}`">
                                0{{jieIndex+1}} | {{jieItem.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </el-scrollbar>
        </div>
        <!--        <div class="course-left exam" v-if="leftVal == 2">-->
        <!--            <el-scrollbar class="exam-content" :native="false">-->
        <!--                <div :class="$route.query.id == item.id ?'exam-item active':'exam-item'"-->
        <!--                     v-for="(item,index) in courseExamData" :key="index" @click="changeExam(item.id)">-->
        <!--                    <div class="exam-name">{{item.name}}</div>-->
        <!--                </div>-->
        <!--            </el-scrollbar>-->
        <!--        </div>-->
        <!--        <div class="course-left exam" v-if="leftVal == 3">-->
        <!--            <el-scrollbar class="exam-content" :native="false">-->
        <!--                <div :class="$route.query.id == item.id ?'exam-item active':'exam-item'"-->
        <!--                     v-for="(item,index) in practiceData" :key="index" @click="changePractice(item.id)">-->
        <!--                    <div class="exam-name">{{item.name}}</div>-->
        <!--                </div>-->
        <!--            </el-scrollbar>-->
        <!--        </div>-->
        <div class="course-right">
            <div class="container">
                <router-view v-if="activeName === 'studentFeatured' || 'studentCourseIndex'"
                             :nodeIndex="nodeIndex" :chapterIndex="chapterIndex" @changeShow="changeShow"/>
                <router-view v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    import {getTeaSchoolCourse, course} from '@/utils/apis.js'
    import studentIndex from '../../views/student/train/course/Index'
    import _ from "underscore";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "studentCourseLayout",
        components: {
            studentIndex
        },
        data() {
            return {
                isChosen: true,
                activeName: 'studentCourseIndex',
                //选择的课程
                select_course: '',
                studentCourseList: [],
                //章节
                courseZhangJie: [],
                currentJie: '',
                //总管理员分配的章节
                allocatedCourse: [],
                allocatedCourseNum: null,
                nodeIndex: 0,
                chapterIndex: 0,
                select_list: [],
                module_list: [],
                dataObj: {},
                id: '',
                courseTempList: [],
                leftVal: 1,
                courseExamData: [],
                practiceData: [],
                zhang_arr: [],
            }
        },
        created() {
            this.activeName = this.$route.meta.tabName;
            if (this.activeName === 'studentExamDetail') {
                this.leftVal = 2;
                this.courseExamData = JSON.parse(localStorage.getItem('paperList'))
            } else if (this.activeName === 'studentPracticeDetail') {
                this.leftVal = 3;
                this.practiceData = JSON.parse(localStorage.getItem('projectsList'))
            } else {
                this.getAll();
                if (this.activeName === 'studentFeatured' && this.isChosen == true || this.activeName === 'studentMatingDetail') {
                    this.isChosen = false;
                    this.chapterIndex = localStorage.getItem('studentChapter');
                    this.nodeIndex = localStorage.getItem('studentNode');
                    this.currentJie = Number(this.nodeIndex);
                }
            }
        },
        watch: {
            $route(route) {
                if (route.name === 'studentCourseIndex') {
                    this.activeName = 'studentCourseIndex';
                    this.isChosen = true;
                    this.currentJie = ''
                    this.leftVal = 1;
                    this.getAll();
                } else if (route.name === 'studentFeatured') {
                    this.activeName = 'studentFeatured';
                    this.isChosen = false;
                    this.leftVal = 1;
                    this.chapterIndex = localStorage.getItem('studentChapter');
                    this.nodeIndex = localStorage.getItem('studentNode');
                    this.currentJie = Number(this.nodeIndex);
                    this.getAll();
                    // this.currentJie = 0;
                } else if (route.name === 'studentExamDetail') {
                    this.courseExamData = JSON.parse(localStorage.getItem('paperList'))
                } else if (route.name === 'studentPracticeDetail') {
                    this.practiceData = JSON.parse(localStorage.getItem('projectsList'));
                }
            }
        },
        methods: {
            setClass(index) {
                if (this.zhang_arr.indexOf(index) !== -1) {
                    return 'isAllocatedClass';
                } else {
                    return '';
                }

            },
            getAll() {
                this.getTeacherCourseList();
            },
            choseAll() {
                this.isChosen = true;
                this.chapterIndex = '';
                this.nodeIndex = '';
                this.currentJie = '';
                this.$router.push({
                    path: '/student/course/index',
                    query: {
                        course_id: this.select_course
                    }
                })
            },
            //选择课程
            chooseCourse(val) {
                this.currentJie = 0;
                this.chapterIndex = 0;
                this.nodeIndex = 0;
                this.module_list = new Array(14).fill({})
                this.select_list = this.module_list;
                this.$router.push({
                    path: '/student/course/index',
                    query: {
                        course_id: val,
                    },
                });
                this.getCourseInfo(val);
            },
            getTeacherCourseList() {
                getTeaSchoolCourse().then((res) => {
                    if (res.code === 200) {
                        this.studentCourseList = res.data;
                        this.allocatedCourse = res.data;
                        if (this.$route.query.course_id) {
                            this.select_course = Number(this.$route.query.course_id);
                        } else {
                            this.select_course = res.data[0].course_id;
                            this.$router.push({
                                query: {
                                    course_id: this.select_course,
                                },
                            });
                        }
                        if (this.select_course) {
                            this.getCourseInfo(this.select_course);
                        }
                    }
                })
            },
            //获取课程详情
            getCourseInfo(courseId) {
                let param = {
                    id: courseId
                }
                course(param).then((res) => {
                    if (res.code == 200) {
                        this.courseZhangJie = res.data.course_resources;
                        let zhang_arr = [];
                        _.each(this.allocatedCourse, (_item) => {
                            if (Number(_item.course_id) === Number(courseId)) {
                                zhang_arr = _item.data;
                            }
                        });
                        for (let i in zhang_arr) {
                            this.zhang_arr.push(zhang_arr[i].id - 1)
                        }
                    }
                })
            },
            clickJie(index, jIndex) {
                if (this.zhang_arr.indexOf(index) !== -1) {
                    this.isChosen = false;
                    this.chapterIndex = index;
                    this.nodeIndex = jIndex;
                    localStorage.setItem('studentChapter', index);
                    localStorage.setItem('studentNode', jIndex);
                    localStorage.setItem('currentCourse', this.select_course);
                    // this.getCourseTempList()
                    this.$router.push({
                        path: '/student/course/featured',
                        query: {
                            course_id: this.select_course,
                        }
                    });
                    this.currentJie = jIndex;
                } else {
                    this.$message({
                        type: 'warning',
                        message: '该节内容未授权',
                        duration: 1000
                    });
                }
            },
            changeShow(val) {
                this.leftVal = val;
                if (val === 1) {
                    this.isChosen = false;
                    this.chapterIndex = localStorage.getItem('studentChapter');
                    this.nodeIndex = localStorage.getItem('studentNode');
                    this.currentJie = Number(this.nodeIndex)
                    this.$router.push({
                        path: '/student/course/featured',
                        query: {
                            course_id: localStorage.getItem('currentCourse'),
                        }
                    });
                }
            },
            changeExam(id) {
                this.$router.push({
                    query: {
                        id: id,
                        course_id: this.select_course,
                        chapter_id: Number(this.chapterIndex) + 1,
                        lesson_id: Number(this.nodeIndex) + 1
                    }
                })
            },
            changePractice(id) {
                this.$router.push({
                    query: {
                        id: id,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    /*::v-deep.el-tabs__nav-scroll{*/
    /*    display: flex;*/
    /*    justify-content: center!important;*/
    /*}*/
    .student-index-container {
        display: flex;
        justify-content: flex-start;
        height: calc(100% - 60px);
        box-sizing: border-box;
        background: #F7F8FC;
        overflow: auto;
        position: fixed;
        width: calc(100% - 230px);
        top: 60px;
        bottom: 0;

        .course-left {
            display: flex;
            flex-direction: column;
            width: 300px;
            height: 100%;
            background-color: #fff;
            margin-right: 20px;

            &.exam {
                width: 210px;
            }

            .select-course {
                width: 260px;
                height: 55px;
                background: #F5F7FA;
                border: 1px solid #DDDDDD;
                border-radius: 6px;
                margin: 20px;

                ::v-deep .el-input__inner {
                    color: #333;
                    border: transparent;
                    background-color: #F5F7FA;
                    height: 55px;
                }
            }

            .item-title {
                position: relative;
                font-size: 15px;
                margin: 0 20px;
                height: 40px;
                display: flex;
                align-items: center;

                &:before {
                    content: '';
                    position: absolute;
                    top: 17px;
                    width: 6px;
                    height: 10px;
                    background: #2DC079;
                    border-radius: 3px;
                }

                .text {
                    margin-left: 15px;
                    font-size: 16px;
                }

                &.choseAll {
                    background: #2DC079;
                    border-radius: 20px;
                    cursor: pointer;
                    color: #ffffff;

                    &:before {
                        content: none;
                    }
                }
            }

            .zhang-jie {
                flex: 1;
                height: 1%;

                ::v-deep .el-scrollbar__wrap {
                    overflow-x: hidden;
                }

                .zhang-jie-item {
                    padding: 0 20px 20px;
                    color: #999;

                    .zhang {
                        height: 30px;
                        line-height: 30px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        position: relative;

                        &:before {
                            content: '';
                            position: absolute;
                            top: 9px;
                            width: 6px;
                            height: 10px;
                            background: #2DC079;
                            border-radius: 3px;
                        }

                        .text {
                            margin-left: 15px;
                            font-size: 16px;
                        }

                    }

                    .jie-box {
                        background: #F6FDFC;

                        .jie-inside-box {
                            padding: 8px 0;
                        }
                    }

                    .jie {
                        cursor: pointer;
                        height: 40px;
                        line-height: 30px;
                        /*color: #409EFF;*/
                        padding-left: 40px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                    }

                    .current {
                        background: #2DC079;
                        border-radius: 20px;
                        color: #ffffff;
                    }

                    &.isAllocatedClass {
                        color: #333;

                        .jie {
                            cursor: pointer;
                        }
                    }
                }
            }

            .exam-content {
                flex: 1;
                height: 1%;

                ::v-deep .el-scrollbar__wrap {
                    margin-top: 20px;
                    overflow-x: hidden;
                }

                .exam-item {
                    width: 210px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &.active {
                        background: #E7F6EF;
                        color: #2DC079;
                    }

                    .exam-name {
                        margin-left: 37px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap
                    }
                }
            }
        }

        .course-right {
            display: flex;
            flex-direction: column;
            position: relative;
            flex: 1;
            width: 1%;
            margin: 20px 20px 0 0;

            .container {
                flex: 1;
                height: 1%;
            }

            .index-tabs {
                width: 100%;
                height: auto;
                background: transparent;
                padding: 0;

                ::v-deep .el-tabs__header {
                    background-color: #fff;
                    padding: 0 20px;

                    ::v-deep .el-tabs__active-bar {
                        display: none;
                    }

                    .el-tabs__nav {
                        width: calc(100% - 209px);
                        display: flex;
                        justify-content: center;

                        .el-tabs__item {
                            height: 60px;
                            line-height: 60px;

                            &.is-active {
                                &:after {
                                    height: 2px;
                                    width: calc(100% - 40px);
                                    background-color: #409EFF;
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    left: 20px;
                                }

                                &:last-child:after {
                                    width: calc(100% - 20px);
                                }

                                &:nth-child(2):after {
                                    width: calc(100% - 20px);
                                    left: 0;
                                }
                            }
                        }
                    }

                    .el-tabs__nav-wrap::after {
                        background-color: transparent;
                    }
                }

                ::v-deep .el-tabs__content {
                    background: #f4f5f5;
                }
            }

            .search-input {
                position: absolute;
                top: 15px;
                right: 20px;
                width: 270px;

                ::v-deep .el-input__inner {
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
</style>
