<template>
    <div class="featured-content">
        <!--课程首页-->
        <el-scrollbar class="course-index">
            <div class="course-content">
                <div class="top-box">
                    <div class="top-leftBox">
                        <img v-if="courseInfo.cover !== null" :src="courseInfo.cover" alt="">
                        <img v-else src="../../../../assets/images/teacher/course/s_course_bg.png" alt="">
                    </div>
                    <div class="top-rightBox">
                        <div class="rightBox-course">
                            <div class="course-title-box1">{{courseInfo.name}}</div>
                            <div class="course-title-box2" v-if="courseInfo.type === 1">
                                <i class="iconfont">&#xe60d;</i>精品课程
                            </div>
                        </div>
                        <div class="rightBox-course">
                            <div class="course-title-midBox">
                                职业名称：<span>{{courseInfo.job_title}}</span>
                            </div>
                        </div>
                        <div class="rightBox-course">
                            <div class="course-title-midBox">
                                职业等级：
                                <span>{{courseInfo.job_level}}</span>
                            </div>
                        </div>
                        <div class="rightBox-course">
                            <div class="course-title-midBox">
                                鉴定方式、鉴定时间：
                                <span>{{courseInfo.appraisal_way_time}}</span>
                            </div>
                        </div>
                        <div class="rightBox-course">
                            <div class="course-title-midBox">
                                考核内容：
                                <span>{{courseInfo.intro}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-box">
                    <div class="item-content">
                        <div class="item-head">
                            <span class="item-title">鉴定概要</span>
                        </div>
                        <div class="item-main">
                            <div class="item-tree-content">
                                <div class="item-tree one">
                                    <div class="item-tree one-title">
                                        <span class="title-index">01</span>
                                        <span class="title-text">理论知识</span>
                                    </div>
                                </div>
                                <div class="item-tree two"
                                     v-for="(sItem,sIndex) in courseInfo.knowledge" :key="sIndex">
                                    <div class="item-tree two-title">
                                        <span>{{sItem.name}}</span>
                                    </div>
                                    <div class="item-tree three" v-for="(gItem,gIndex) in sItem.children" :key="gIndex">
                                        <div class="item-tree three-title">
                                            <span>{{gIndex+1}}.{{gItem.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="item-tree-content">
                                <div class="item-tree one">
                                    <div class="item-tree one-title">
                                        <span class="title-index">02</span>
                                        <span class="title-text">专业技能</span>
                                    </div>
                                </div>
                                <div class="item-tree two"
                                     v-for="(sItem,sIndex) in courseInfo.major" :key="sIndex">
                                    <div class="item-tree two-title">
                                        <span>{{sItem.name}}</span>
                                    </div>
                                    <div class="item-tree three" v-for="(gItem,gIndex) in sItem.children" :key="gIndex">
                                        <div class="item-tree three-title">
                                            <span>{{gIndex+1}}.{{gItem.name}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item-content">
                        <div class="item-head">
                            <span class="item-title">鉴定内容</span>
                        </div>
                        <div class="item-main">
                            <div class="item-table">
                                <div class="table-title">理论知识鉴定内容</div>
                                <div class="table-content">
                                    <div class="table-wrap"
                                         v-html="courseInfo.appraisal_content.theoretical_knowledge_content"></div>
                                </div>
                            </div>
                            <div class="item-table">
                                <div class="table-title">专业技能考核内容</div>
                                <div class="table-content">
                                    <div class="table-wrap"
                                         v-html="courseInfo.appraisal_content.professional_skill_content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-scrollbar>
    </div>
</template>

<script>
    import {course} from '@/utils/apis.js'

    export default {
        name: "studentCourseIndex",
        data() {
            return {
                courseInfo: {
                    name: '',
                    class: [],
                    classify_id: '',
                    intro: '',
                    cover: '',
                    job_title: '',
                    job_level: '',
                    appraisal_way_time: '',
                    sort: '',
                    knowledge: [],
                    major: [],
                    appraisal_content: {
                        theoretical_knowledge_content: '',
                        professional_skill_content: '',
                    }
                },
            }
        },
        watch: {
            $route(route) {
                if(this.$route.query.course_id !== undefined){
                    this.getCourseContent();
                }
            }
        },
        mounted() {
            if(this.$route.query.course_id !== undefined){
                this.getCourseContent();
            }
        },
        methods: {
            getCourseContent() {
                let param = {
                    id: this.$route.query.course_id
                }
                course(param).then((res) => {
                    if (res.code == 200) {
                        this.courseInfo.name = res.data.name;
                        this.courseInfo.cover = res.data.cover;
                        this.courseInfo.intro = res.data.intro;
                        this.courseInfo.job_title = res.data.job_title;
                        this.courseInfo.job_level = res.data.job_level;
                        this.courseInfo.appraisal_way_time = res.data.appraisal_way_time;
                        this.courseInfo.sort = res.data.sort;
                        this.courseInfo.knowledge = res.data.appraisal_outline.theoretical_knowledge;
                        this.courseInfo.major = res.data.appraisal_outline.professional_skill;
                        this.courseInfo.appraisal_content = res.data.appraisal_content;
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-dialog {
        width: calc(100% - 120px);
        min-width: 1000px;
    }

    ::v-deep .el-dialog__header::after {
        content: '';
        width: 100%;
        height: 10px;
        display: block;
        margin: 0 auto;
        border-bottom: 1px solid #EEEEEE;
    }

    ::v-deep .el-dialog__footer {
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        position: relative;
    }

    .featured-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;

        ::v-deep .el-dialog {
            margin-top: 10vh !important;
        }

        .course-index {
            width: 100%;
            height: 100%;
            /*min-width: 1460px;*/
            background-size: 100% 100%;
            background: #ffffff;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;

                .el-scrollbar__view {
                    height: 100%;
                }
            }

            .course-content {
                height: 100%;
            }

            .top-box {
                width: 100%;
                background: #ffffff;
                display: flex;
                /*flex: 1;*/

                .top-leftBox {
                    width: 586px;
                    height: 330px;
                    padding: 60px 60px 32px 60px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .top-rightBox {
                    height: 330px;
                    max-height: 330px;
                    padding: 60px 60px 32px 0;

                    .rightBox-course {
                        display: flex;
                        margin-top: 20px;

                        .course-title-box1 {
                            font-size: 24px;
                            font-weight: 400;
                            color: #313F54;
                        }

                        .course-title-box2 {
                            width: 110px;
                            background-image: url("../../../../assets/images/teacher/course/courseIndex.png");
                            margin-left: 30px;
                            text-align: center;
                            color: #FFFFFF;
                            height: 30px;

                            i {
                                margin-right: 8px;
                                font-size: 14px;
                            }
                        }

                        .course-title-midBox {
                            font-size: 16px;
                            color: #313F54;
                            max-width: 700px;
                            max-height: 150px;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 4;

                            span {
                                font-size: 14px;
                                word-break: break-all;
                                line-height: 1.5;
                                max-height: 120px;
                            }
                        }
                    }
                }
            }

            .bottom-box {
                width: 100%;
                flex: 1;
                background: #ffffff;
                margin-top: 30px;

                .item-content {
                    &:last-child {
                        padding: 100px 0;

                    }

                    .item-head {
                        text-align: center;
                    }

                    .item-title {
                        font-size: 20px;
                        color: #333333;
                        position: relative;

                        &:before {
                            position: absolute;
                            width: 34px;
                            height: 2px;
                            content: '';
                            background: #2DC079;
                            top: 14px;
                            left: -64px;
                        }

                        &:after {
                            position: absolute;
                            width: 34px;
                            height: 2px;
                            content: '';
                            background: #2DC079;
                            top: 14px;
                            right: -64px;
                        }
                    }

                    .item-main {
                        padding: 80px 60px 0;
                    }

                    .item-tree-content {
                        margin-top: 23px;

                        .item-tree {
                            font-size: 20px;
                            color: #333333;

                            &.one-title {
                                .title-index {
                                    font-weight: bold;
                                }

                                .title-text {
                                    margin-left: 5px;
                                }
                            }

                            &.two-title {
                                display: flex;
                                align-items: center;
                                height: 48px;
                                background: #EEFFFC;
                                margin-top: 10px;

                                span {
                                    padding-left: 60px;
                                }
                            }

                            &.three-title {
                                display: flex;
                                align-items: center;
                                height: 48px;
                                background: #F6FDFC;
                                margin-top: 10px;

                                span {
                                    padding-left: 90px;
                                }
                            }
                        }
                    }

                    .item-table {

                        &:last-child {
                            margin-top: 41px;
                        }

                        .table-title {
                            font-size: 18px;
                        }

                        .table-content {
                            margin-top: 22px;

                            ::v-deep.table-wrap {
                                table {
                                    border-collapse: collapse !important;
                                    width: 100% !important;
                                    border-color: transparent;
                                    border-bottom: none;
                                    margin-top: -4px;

                                    tr {
                                        height: auto !important;

                                        &:nth-child(even) {
                                            td {
                                                background: #E9F6EF;
                                                color: #333333;
                                            }
                                        }

                                        &:nth-child(odd) {
                                            td {
                                                background: #56CA95;
                                            }
                                        }

                                        th {
                                            background: #56CA95;
                                        }

                                        th, td {
                                            border-right: 4px solid #fff;
                                            border-top: 4px solid #fff;

                                            &:first-child {
                                                border-left: 0;
                                                /*text-align: center;*/
                                            }

                                            &:last-child {
                                                border-right: 0;
                                                /*text-align: center;*/
                                            }
                                        }

                                        th {
                                            text-align: center;
                                            padding: 20px;
                                            height: 20px !important;
                                            line-height: 20px;
                                            box-sizing: border-box;
                                            font-size: 18px;
                                            color: #fff;
                                            white-space: nowrap;
                                        }

                                        td {
                                            height: auto !important;
                                            text-align: center;
                                            padding: 14px;
                                            font-size: 18px;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>