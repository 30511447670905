import router from './router'
import store from './store'
import { adminRoutes, LoginRoutes, schoolRoutes, teacherRoutes, judgesRoutes, studentRoutes, evaluationRoutes} from '@/router'


const whiteList = ['login','upload'] // 不需要登录就可以访问的路由白名单

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = to.meta.title

    // 定义3个端各自的token，方便走各自的路由逻辑，互不干扰
    const rolesToken = {
        1: 'adminToken',
        2: 'schoolToken',
        3: 'teacherToken',
        4: 'judgesToken',
        5: 'studentToken',
        6: 'evaluationToken'
    }

    const hasToken = localStorage.getItem(rolesToken[to.meta.role]) // 获取当前访问客户端的token
    if (!hasToken) {
        /* has no token*/
        if (whiteList.indexOf(to.name) !== -1) {
            next()
            return
        }
        next('/')
        // const loginRoute = LoginRoutes.find(item => item.meta.role === to.meta.role)
        // next({path: loginRoute.path})
    }
    // 如果访问的路由存在对应的token，走各自流程
    switch (to.meta.role) {
        case 1:
            // admin
            // 合并登录页
            // if (to.path === '/admin') {
            //     next({path: '/admin/index'})
            // } else {
            const adminMenu = store.getters.adminMenu && store.getters.adminMenu.length > 0;
            if (!adminMenu) {
                store.dispatch('generateAdminRoutes', adminRoutes)
            }
            next()
            // }
            break;
        case 2:
            //school
            // 合并登录页
            // if (to.path === '/') {
            //     // if is logged in, redirect to the home page
            //     next({path: '/store/index'})
            // } else {
            // 获取teacherMenu是否存在
            const schoolMenu = store.getters.schoolMenu && store.getters.schoolMenu.length > 0;
            if (!schoolMenu) {
                store.dispatch('generateSchoolRoutes', schoolRoutes)
            }
            next()
            // }
            break;
        case 3:

            //teacher
            // 合并登录页
            // if (to.path === '/') {
            //     // if is logged in, redirect to the home page
            //     next({path: '/store/index'})
            // } else {
            // 获取teacherMenu是否存在
            const teacherMenu = store.getters.teacherMenu && store.getters.teacherMenu.length > 0;
            if (!teacherMenu) {
                store.dispatch('generateTeacherRoutes', teacherRoutes)
            }
            next()
            // }
            break;
        case 4:
            //judges
            // 合并登录页
            // if (to.path === '/') {
            //     // if is logged in, redirect to the home page
            //     next({path: '/store/index'})
            // } else {
            // 获取teacherMenu是否存在
            const judgesMenu = store.getters.judgesMenu && store.getters.judgesMenu.length > 0;
            if (!judgesMenu) {
                store.dispatch('generateJudgesRoutes', judgesRoutes)
            }
            next()
            // }
            break;
        case 5:
            //student
            // 合并登录页
            // if (to.path === '/') {
            //     // if is logged in, redirect to the home page
            //     next({path: '/store/index'})
            // } else {
            // 获取studentMenu是否存在
            const studentMenu = store.getters.studentMenu && store.getters.studentMenu.length > 0;
            if (!studentMenu) {
                store.dispatch('generateStudentRoutes', studentRoutes)
            }
            next()
            // }
            break;
        case 6:
            const evaluationMenu = store.getters.evaluationMenu && store.getters.evaluationMenu.length > 0;
            if (!evaluationMenu) {
                store.dispatch('generateEvaluationRoutes', evaluationRoutes)
            }
            next()
            // }
            break;
        default:
            // 三个端共用的路由，直接访问
            next()
    }
})

router.afterEach(() => {
})