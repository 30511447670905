<template>
    <div class="promotion-wrap">
        <div class="top-tab" v-if="platform == 0">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="直播间管理" name="studentTrainCenterStartTrainingLivePromotionIndex"></el-tab-pane>
                <el-tab-pane label="商品库" name="studentTrainCenterStartTrainingGoodsLibrary"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="top-tab" v-else>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane label="直播间管理" name="studentExamCenterLivePromotionIndex"></el-tab-pane>
                <el-tab-pane label="商品库" name="studentExamCenterGoodsLibrary"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="btn-content">
            <el-button type="primary" @click="downloadFile">下载素材</el-button>
        </div>
        <div class="container">
            <router-view/>
        </div>
    </div>
</template>

<script>
    import {stuOpGetExamInfo} from '@/utils/apis'
    export default {
        name: "livePromotionLayout",
        data() {
            return {
                tabName: 'studentTrainCenterStartTrainingLivePromotionIndex',
                opId: Number(this.$route.query.id) || null,
                courseId: Number(this.$route.query.course_id) || null,
                platform: 0,//0是实训，1是考试
            }
        },
        watch: {
            $route(route) {
                this.platform = route.meta.type;
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
            this.platform = this.$route.meta.type;
        },
        methods: {
            //切换
            toggleTab(tab) {
                if (this.platform == 0) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            id: this.opId,
                            course_id: this.courseId,
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                    })
                }
            },
            downloadFile(){
                let params = {
                    type: 'network_promotion'
                }
                if (this.opId) {
                    params.op_id = this.opId
                }
                stuOpGetExamInfo(params).then((res) => {
                   window.open(res.data.file_download)
                }).catch((err) => {
                    console.log(err);
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .promotion-wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }

    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            top: 46px;
            height: 1px;
            background: #F1F5FF;
        }

        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }

    .inner-content {
        margin: 0 20px;
        flex: 1;
        height: 1%;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .tabs-full {
        width: 1%;
        flex: 1;

        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }

            .el-tabs__header {
                margin-bottom: 0;
            }

            .el-tabs__active-bar {
                display: none;
            }

            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;

                &.is-active {
                    background: #E7F6EF;
                }
            }

            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }

            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }

    .btn-content {
        position: absolute;
        right: 40px;
    }

</style>