<template>
    <div class="examData-wrapper">
        <div class="top-tab" :class="role === '3' ? '' : 'tabs'">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-if="role === '3' && level == 1">
                <el-tab-pane label="理论题" name="evaluationCenterTheoreticalQuestionsIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.goods_info_collect === 9 || modules.goods_info_collect === 10"
                             label="商品信息采集与处理" name="evaluationCenterGoodsInfoIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.shop_decoration === 12 || modules.shop_decoration === 13 || modules.shop_decoration === 14"
                             label="网店装修" name="evaluationCenterOnlineShopDecorationIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.network_promotion" label="网络推广" name="evaluationCenterWebPromotionIndex"></el-tab-pane>
            </el-tabs>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-if="role === '3' && level == 2">
                <el-tab-pane label="理论题" name="evaluationCenterTheoreticalQuestionsIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.store_visual_design" label="网店视觉设计" name="evaluationCenterStoreVisualDesignIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.network_marketing === 36" label="网络营销" name="evaluationCenterCyberMarketingIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.store_customer_service_level_three === 42" label="网络客户服务" name="evaluationCenterCyberCustomerIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.data_analysis_application === 46" label="电子商务数据分析与应用" name="evaluationCenterECommerceAnalysisApplicationIndex"></el-tab-pane>
            </el-tabs>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-if="role === '4' && level == 1">
<!--                <el-tab-pane label="理论题" name="judgesTheoreticalQuestionsIndex"></el-tab-pane>-->
                <el-tab-pane v-if="[9,10].includes(modules.goods_info_collect)" label="商品信息采集与处理" name="judgesGoodsInfoIndex"></el-tab-pane>
<!--                <el-tab-pane v-if="[12, 13, 14].includes(modules.shop_decoration)"-->
<!--                             label="网店装修" name="judgesOnlineShopDecorationIndex"></el-tab-pane>-->
                <el-tab-pane v-if="[12].includes(modules.shop_decoration)"
                             label="线上店铺设计与装修" name="judgesOnlineShopDecorationPageSet"></el-tab-pane>
                <!-- <el-tab-pane v-if="modules.network_promotion === 19" label="网络推广" name="judgesWebPromotionIndex"></el-tab-pane> -->
            </el-tabs>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-if="role === '4' && level == 2">
<!--                <el-tab-pane label="理论题" name="judgesTheoreticalQuestionsIndex"></el-tab-pane>-->
<!--                <el-tab-pane v-if="modules.store_visual_design" label="网店视觉设计" name="judgesCenterStoreVisualDesignIndex"></el-tab-pane>-->
                <el-tab-pane v-if="modules.store_visual_design === 30" label="线上店铺设计与装修" name="judgesOnlineShopDecorationPageSet"></el-tab-pane>
                <el-tab-pane v-if="modules.network_marketing === 36" label="网络营销" name="judgesCenterCyberMarketingIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.store_customer_service_level_three === 42" label="网络客户服务" name="judgesCyberCustomerIndex"></el-tab-pane>
                <el-tab-pane v-if="modules.data_analysis_application === 46" label="电子商务数据分析与应用" name="judgesCenterECommerceAnalysisApplicationIndex"></el-tab-pane>
            </el-tabs>
            <div class="right">
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import {examModules} from '@/utils/apis'
    export default {
        data() {
            return {
                tabName: '',
                role: '',
                isCyberCustomer:false,
                level:this.$route.query.level,
                modules: {}
            }
        },
        created() {
            this.role = localStorage.getItem('role');
            this.tabName = this.$route.meta.tabName;
            // this.checkIsCyberCustomer();
            this.getExamModules()
        },
        methods: {
            toggleTab(tab) {
                let module = void 0
                // 四级
                if (tab.name === 'evaluationCenterGoodsInfoIndex' || tab.name === 'judgesGoodsInfoIndex') {
                    module = this.modules.goods_info_collect || void 0
                }
                if (tab.name === 'evaluationCenterOnlineShopDecorationIndex' || tab.name === 'judgesOnlineShopDecorationIndex') {
                    module = this.modules.shop_decoration || void 0
                }
                if (tab.name === 'evaluationCenterWebPromotionIndex' || tab.name === 'judgesWebPromotionIndex') {
                    module = this.modules.network_promotion || void 0
                }
                // 三级
                if (tab.name === 'evaluationCenterStoreVisualDesignIndex' || tab.name === 'judgesCenterStoreVisualDesignIndex') {
                    module = this.modules.store_visual_design || void 0
                }
                if (tab.name === 'evaluationCenterCyberMarketingIndex' || tab.name === 'judgesCenterCyberMarketingIndex') {
                    module = this.modules.network_marketing || void 0
                }
                if (tab.name === 'evaluationCenterCyberCustomerIndex' || tab.name === 'judgesCyberCustomerIndex') {
                    module = this.modules.store_customer_service_level_three || void 0
                }
                if (tab.name === 'evaluationCenterECommerceAnalysisApplicationIndex' || tab.name === 'judgesCenterECommerceAnalysisApplicationIndex') {
                    module = this.modules.data_analysis_application || void 0
                }

                this.$router.push({
                    name: tab.name,
                    query:{
                      examId: this.$route.query.examId,
                      level:this.level,
                        moduleId: module
                    }
                })
            },
            goBack() {
                if (this.role === '3') {
                    this.$router.push({
                        path: '/evaluationCenter/scoreManagement',
                    })
                } else {
                    this.$router.push({
                        path: '/judges/grading/index',
                    })
                }

            },
            // checkIsCyberCustomer(){
            //     let exam_id = this.$route.query.examId
            //     customer_relations_check({exam_id:exam_id}).then(res=>{
            //         this.isCyberCustomer = res.data.has
            //     })
            // },
            getExamModules() {
                examModules(this.$route.query.examId).then((res) => {
                    this.modules = res.data
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .tabs {
        margin: 20px 20px 0 !important;
    }
    .top-tab {
        margin: 0 20px 0;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>