<template>
    <el-scrollbar class="info-step">
        <div class="info-step-header">
            <el-breadcrumb>
                <el-breadcrumb-item>展位</el-breadcrumb-item>
                <el-breadcrumb-item>推广管理</el-breadcrumb-item>
                <el-breadcrumb-item>新建计划</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="info-step-box">
            <div class="info-step-left">
                <div class="left-creation">
                    <div class="step-one">
                        <div class="step-name" :class="stepActive >= 1 ? 'isActive' : ''">
                            <div>
                                <span class="num">1</span>
                                <span class="name">创建计划</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                        <!--<div class="step-small-name">-->
                            <!--<div>-->
                                <!--<span class="num"></span>-->
                                <!--<span class="name">投放时间</span>-->
                            <!--</div>-->
                            <!--<span class="icon"></span>-->
                        <!--</div>-->
                        <div class="step-small-name" v-show="stepActive === 1" :class="stepActive >= 1 ? 'isActive' : ''">
                            <div>
                                <span class="num"></span>
                                <span class="name">计划设置</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                    </div>
                    <div class="step-one">
                        <div class="step-name" :class="stepActive >= 2 ? 'isActive' : ''">
                            <div>
                                <span class="num">2</span>
                                <span class="name">创建单元</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                        <div class="step-small-name" v-show="stepActive === 2" :class="stepActive >= 2 ? 'isActive' : ''">
                            <div>
                                <span class="num"></span>
                                <span class="name">投放位置</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                        <div class="step-small-name" v-show="stepActive === 2" :class="stepActive >= 2 ? 'isActive' : ''">
                            <div>
                                <span class="num"></span>
                                <span class="name">定向设置</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                        <div class="step-small-name" v-show="stepActive === 2" :class="stepActive >= 2 ? 'isActive' : ''">
                            <div>
                                <span class="num"></span>
                                <span class="name">广告出价</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                        <div class="step-small-name" v-show="stepActive === 2" :class="stepActive >= 2 ? 'isActive' : ''">
                            <div>
                                <span class="num"></span>
                                <span class="name">广告名称</span>
                            </div>
                            <span class="icon"></span>
                        </div>
                    </div>
                    <div class="step-one">
                        <div class="step-name" :class="stepActive === 3 ? 'isActive' : ''">
                            <div>
                                <span class="num">3</span>
                                <span class="name">创建创意</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info-step-right">
                <keep-alive>
                    <router-view />
                </keep-alive>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    export default {
        data(){
            return {
                stepActive: 1
            }
        },
        created() {
            if (this.$route.query.step === '1') {
                this.stepActive = 1;
            } else if (this.$route.query.step === '2') {
                this.stepActive = 2;
            } else if (this.$route.query.step === '3') {
                this.stepActive = 3;
            }
        },
        watch: {
            $route(to){
                if (to.query.step === '1') {
                    this.stepActive = 1;
                } else if (to.query.step === '2') {
                    this.stepActive = 2;
                } else if (to.query.step === '3') {
                    this.stepActive = 3;
                }
            },
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .stepActive {
        background: #20D08C;
        border-color: #20D08C!important;
    }
    .xianActive {
        border-color: #20D08C!important;
    }
    .info-step {
        height: 100%;
        background: #F7F9FB;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .info-step-header {
            padding: 22px 0 20px 40px
        }
        .info-step-box {
            display: flex;
            .info-step-left {
                width: 190px;
                margin-left: 40px;
                .left-creation {
                    display: flex;
                    flex-direction: column;
                    p:nth-child(1) {
                        margin: 0;
                        padding: 20px 0;
                        line-height:1;
                        /*color: #333333;*/
                        color: #959697;
                        font-size: 48px;
                        span {
                            font-size: 24px;
                        }
                        &.isActive {
                            color: #333;
                        }
                    }
                    .step-one {
                        .step-name {
                            display: flex;
                            flex-direction: column;
                            &.isActive {
                                .num {
                                    background: #1FD08C;
                                    color: #fff;
                                }
                                .name {
                                    color: #333333;
                                }
                            }
                            .num {
                                width: 24px;
                                height: 24px;
                                background: #fff;
                                border-radius: 50%;
                                display: inline-block;
                                text-align: center;
                                color: #1FD08C;
                                line-height: 24px;
                                font-size: 16px;
                                font-family: PingFang SC;
                                font-weight: bold;
                                border: 1px solid #1FD08C;
                            }
                            .name {
                                font-size: 18px;
                                font-family: PingFang SC;
                                font-weight: bold;
                                color: #999;
                                margin-left: 15px;
                            }
                            .icon {
                                display: inline-block;
                                width: 1px;
                                height: 41px;
                                background: #20D08C;
                                margin: 10px;
                            }
                        }
                        .step-small-name {
                            display: flex;
                            flex-direction: column;
                            &.isActive {
                                .num {
                                    background: #1FD08C;
                                    color: #fff;
                                }
                                .name {
                                    color: #333333;
                                }
                            }
                            .num {
                                width: 18px;
                                height: 18px;
                                background: #fff;
                                border-radius: 50%;
                                display: inline-block;
                                text-align: center;
                                color: #1FD08C;
                                line-height: 24px;
                                font-size: 16px;
                                font-family: PingFang SC;
                                font-weight: bold;
                                border: 1px solid #1FD08C;
                            }
                            .name {
                                font-size: 16px;
                                font-family: PingFang SC;
                                font-weight: bold;
                                color: #999;
                                margin-left: 15px;
                            }
                            .icon {
                                display: inline-block;
                                width: 1px;
                                height: 41px;
                                background: #20D08C;
                                margin: 10px;
                            }
                        }
                    }
                }
            }
            .info-step-right{
                width: 1%;
                flex: 1;
                margin: 20px 30px 50px 0;
            }
        }
    }
</style>