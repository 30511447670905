<template>
    <div class="examData-wrapper">
        <div class="top-tab" v-if="isExamOrTrain">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane v-if="social_type === 1" label="软文营销" name="studentExamInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane v-if="social_type === 2" label="短视频营销" name="studentExamInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane v-if="social_type === 3" label="直播营销" name="studentExamInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane label="在售商品管理" name="studentExamInternetMarketingSocialECommerceGoodsManage"></el-tab-pane>
            </el-tabs>
            <el-button type="primary" @click="clickDownload" style="margin-bottom: 16px">下载素材</el-button>
        </div>
        <div class="top-tab" v-else>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab">
                <el-tab-pane v-if="social_type === 1" label="软文营销" name="studentTrainCenterInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane v-if="social_type === 2" label="短视频营销" name="studentTrainCenterInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane v-if="social_type === 3" label="直播营销" name="studentTrainCenterInternetMarketingSocialECommerceSoftText"></el-tab-pane>
                <el-tab-pane label="在售商品管理" name="studentTrainCenterInternetMarketingSocialECommerceGoodsManage"></el-tab-pane>
            </el-tabs>
            <el-button type="primary" @click="clickDownload" style="margin-bottom: 16px">下载素材</el-button>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import {stuOpGetExamInfo} from '@/utils/apis'

    export default {
        name: "SocialECommerceLayout",
        data() {
            return {
                examModuleId: Number(this.$route.query.exam_module_id) || '',
                opId: Number(this.$route.query.op_id) || '',
                course_id: Number(this.$route.query.course_id) || '',
                tabName: '',
                isExamOrTrain: localStorage.getItem('examId'), // 有考试， 没有训练
                //下载素材
                downloadMaterial: '',
                //社交电商类型
                social_type: null,
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;

        },
        mounted() {
            this.getDownloadMaterialNetworkMarketing();
        },
        methods: {
            //获取下载素材
            getDownloadMaterialNetworkMarketing() {
                let p = {
                    type: "network_marketing"
                }
                if (this.opId) {
                    p.op_id = this.opId;
                }
                stuOpGetExamInfo(p).then((res) => {
                    this.downloadMaterial = res.data.file_download;
                    this.social_type = res.data.social_type;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //点击下载
            clickDownload() {
                window.location.href = this.downloadMaterial;
            },
            //切换
            toggleTab(tab) {
                if (this.isExamOrTrain) {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            op_id: this.opId,
                            social_type:this.social_type,
                        }
                    })
                } else {
                    this.$router.push({
                        name: tab.name,
                        query: {
                            op_id: this.opId,
                            social_type:this.social_type,
                            course_id:this.course_id,
                        }
                    })
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>