import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import ElementUI from 'element-ui'
import '../theme/index.css'
import './assets/style/common.scss';
import './assets/style/iconfont.css';
import tinymce from 'tinymce'
import VueTinymce from '@packy-tang/vue-tinymce'
import Common from './utils/courseFilter.js';
import Competition from './utils/competition.js';
import echarts from 'echarts';
ElementUI.Dialog.props.lockScroll.default = false
Vue.config.productionTip = false
Vue.prototype.$tinymce = tinymce
Vue.prototype.$common = Common;
Vue.prototype.$competition = Competition;
Vue.prototype.$echarts = echarts;

Vue.filter('showNumber', function(value) {
  if (value === null) {
    return '-'
  }
  return value
})

Vue.use(ElementUI)
Vue.use(VueTinymce)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
