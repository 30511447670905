<template>
    <div class="examData-wrapper">
        <div class="top-tab">
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-if="level == 1">
                <el-tab-pane label="理论题" name="examCenterTrainingDataTheory"></el-tab-pane>
                <el-tab-pane v-if="modules.goods_info_collect" label="商品信息采集与处理" name="examCenterTrainingDataGoodsInfo"></el-tab-pane>
                <el-tab-pane v-if="modules.shop_decoration" label="网店装修" name="examCenterTrainingDataStoreDecorate"></el-tab-pane>
                <el-tab-pane v-if="modules.shop_management" label="网店管理" name="examCenterTrainingDataStoreManage"></el-tab-pane>
                <el-tab-pane v-if="modules.network_promotion" label="网络推广" name="examCenterTrainingDataNetworkPromote"></el-tab-pane>
                <el-tab-pane v-if="modules.store_customer_service" label="网店客户服务" name="examCenterTrainingDataStoreCustomer"></el-tab-pane>
                <el-tab-pane v-if="modules.data_capture_clean" label="电子商务数据收集与清洗" name="examCenterTrainingDataECommerceData"></el-tab-pane>
            </el-tabs>
            <el-tabs v-model="tabName" class="tabs-full" @tab-click="toggleTab" v-else>
                <el-tab-pane label="理论题" name="examCenterTrainingDataTheory"></el-tab-pane>
                <el-tab-pane v-if="modules.store_visual_design" label="网店视觉设计" name="examCenterTrainingDataOnlineShopVisualDesignDetail"></el-tab-pane>
                <el-tab-pane v-if="modules.network_marketing" label="网络营销" name="examCenterTrainingDataCyberMarketing"></el-tab-pane>
                <el-tab-pane v-if="modules.online_transaction_management" label="网上交易管理" name="examCenterTrainingDataOnlineTradeManageDetail"></el-tab-pane>
                <el-tab-pane v-if="modules.store_customer_service_level_three === 42" label="网络客户服务" name="examCenterTrainingDataCyberCustomer"></el-tab-pane>
                <el-tab-pane v-if="modules.store_customer_service_level_three === 43" label="网络客户服务" name="examCenterTrainingDataStoreCustomer"></el-tab-pane>
                <el-tab-pane v-if="modules.data_analysis_application" label="电子商务数据分析与应用" name="examCenterTrainingDataECommerceAnalysisApplicationDetail"></el-tab-pane>
            </el-tabs>
            <div class="right">
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <div class="container">
            <router-view />
        </div>
    </div>
</template>

<script>
    import { examModules } from '@/utils/apis.js'
    export default {
        data() {
            return {
                examId: this.$route.query.examId || null,
                studentId:this.$route.query.id || null,
                tabName: 'examCenterTrainingDataTheory',
                level:this.$route.query.level || null,
                modules: {}
            }
        },
        created() {
            this.tabName = this.$route.meta.tabName;
            this.getExamModules()
        },
        methods: {
            toggleTab(tab) {
                let module = void 0
                // 四级
                if (tab.name === 'examCenterTrainingDataGoodsInfo') {
                    module = this.modules.goods_info_collect || void 0
                }
                if (tab.name === 'examCenterTrainingDataStoreDecorate') {
                    module = this.modules.shop_decoration || void 0
                }
                if (tab.name === 'examCenterTrainingDataStoreManage') {
                    module = this.modules.shop_management || void 0
                }
                if (tab.name === 'examCenterTrainingDataNetworkPromote') {
                    module = this.modules.network_promotion || void 0
                }
                if (Number(this.level) === 1 && tab.name === 'examCenterTrainingDataStoreCustomer') {
                    module = this.modules.store_customer_service || void 0
                }
                if (tab.name === 'examCenterTrainingDataECommerceData') {
                    module = this.modules.data_capture_clean || void 0
                }
                // 三级
                if (tab.name === 'examCenterTrainingDataOnlineShopVisualDesignDetail') {
                    module = this.modules.store_visual_design || void 0
                }
                if (tab.name === 'examCenterTrainingDataCyberMarketing') {
                    module = this.modules.network_marketing || void 0
                }
                if (tab.name === 'examCenterTrainingDataOnlineTradeManageDetail') {
                    module = this.modules.online_transaction_management || void 0
                }
                if (tab.name === 'examCenterTrainingDataCyberCustomer') {
                    module = this.modules.store_customer_service_level_three || void 0
                }
                if (Number(this.level) === 2 && tab.name === 'examCenterTrainingDataStoreCustomer') {
                    module = this.modules.store_customer_service_level_three || void 0
                }
                if (tab.name === 'examCenterTrainingDataECommerceAnalysisApplicationDetail') {
                    module = this.modules.data_analysis_application || void 0
                }
                this.$router.push({
                    name: tab.name,
                    query:{
                        id:this.studentId,
                        examId:this.examId,
                        method: 'teaExamTrain',
                        level:this.level,
                        moduleId: module
                    }
                })
                if(tab.name == 'examCenterTrainingDataNetworkPromote'){
                    this.$router.push({
                        name: tab.name,
                        query:{
                            id:this.studentId,
                            examId:this.examId,
                            type:2,
                            moduleId: module
                        }
                    })
                }
            },
            goBack() {
                this.$router.push({
                    path: '/examCenter/trainingData/student',
                    query: {
                        examId: this.examId,
                        level: this.level
                    }
                })
            },
            getExamModules() {
                examModules(this.$route.query.examId).then((res) => {
                    this.modules = res.data
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .examData-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .container {
            height: 1%;
            flex: 1;
            position: relative;
        }
    }
    .top-tab {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: #F1F5FF;
        }
        .right {
            width: 90px;
            text-align: right;
            margin-top: -5px;
        }
    }
    .tabs-full {
        width: 1%;
        flex: 1;
        ::v-deep {
            .el-tabs__nav-wrap::after {
                content: none;
            }
            .el-tabs__header {
                margin-bottom: 0;
            }
            .el-tabs__active-bar {
                display: none;
            }
            .el-tabs__item {
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                font-weight: normal;
                border-radius: 4px 4px 0 0;
                &.is-active {
                    background: #E7F6EF;
                }
            }
            .el-tabs__item.is-top:nth-child(2) {
                padding-left: 20px;
            }
            .el-tabs__item.is-top:last-child {
                padding-right: 20px;
            }
        }
    }
</style>