import { render, staticRenderFns } from "./InfographicPromotionStepLayout.vue?vue&type=template&id=147a0540&scoped=true"
import script from "./InfographicPromotionStepLayout.vue?vue&type=script&lang=js"
export * from "./InfographicPromotionStepLayout.vue?vue&type=script&lang=js"
import style0 from "./InfographicPromotionStepLayout.vue?vue&type=style&index=0&id=147a0540&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "147a0540",
  null
  
)

export default component.exports