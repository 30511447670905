import http from "./request";
import newHttp from "./new_request";

export const sliceUploadVideo = (params) => {
    return newHttp.post("collect/up_video", params);
};
export const mergeVideoSlice = (params) => {
    return newHttp.post("collect/merge", params);
};
export const sliceUpload = (params) => {
    return newHttp.post("resource/upload", params);
};
export const mergeSlice = (params) => {
    return newHttp.post("resource/merge", params);
};
export const userLogin = (params) => {
    return http.post("login", params);
};
export const userLogout = () => {
    return http.get("logout");
};
export const adminGoodListGood = (params) => {
    return http.get("adminGood/listGood", { params: params });
};
export const operateTheoryCate = (params) => {
    return http.post("cate/add", params);
};
export const getTheoryCateData = (params) => {
    return http.get("cate/page", { params: params });
};
export const deleteTheoryCate = (params) => {
    return http.get("cate/del/" + params.id);
};

export const operateActualCate = (params) => {
    return http.post("cate_opt/save", params);
};
export const getActualCateData = (params) => {
    return http.get("cate_opt/list", { params: params });
};
export const deleteActualCate = (params) => {
    return http.post("cate_opt/del", params);
};

export const tagAdd = (params) => {
    return http.post("tag/add", params);
};
export const tag = (params) => {
    return http.post("tag", params);
};
export const tagPage = (params) => {
    return http.get("tag/page", { params: params });
};
export const tagDel = (id) => {
    return http.get("tag/del/" + id);
};
export const adminGoodRecentlyUsedCategory = () => {
    return http.get("adminGood/recentlyUsedCategory");
};
export const adminGoodGetCategory = (params) => {
    return http.get("adminGood/getCategory", { params: params });
};
export const adminGoodStepOne = (params) => {
    return http.get("adminGood/stepOne", { params: params });
};
export const adminSchoolSchools = (params) => {
    return http.get("school/schools", { params: params });
};
export const adminSchoolAdd = (params) => {
    return http.post("school/add", params);
};
export const adminSchoolInfo = (id) => {
    return http.get("school/info/" + id);
};
export const adminSchoolDel = (id) => {
    return http.get("school/del/" + id);
};
export const adminSchoolAdmins = (params) => {
    return http.get("admin/admins", { params: params });
};
export const adminSchoolAdminAdd = (params) => {
    return http.post("admin/add", params);
};
export const adminSchoolAdminInfo = (id) => {
    return http.get("admin/info/" + id);
};
export const adminSchoolAdminDel = (id) => {
    return http.get("admin/del/" + id);
};
export const adminKeyWordListKeyWord = (params) => {
    return http.get("adminKeyWord/listKeyWord", { params: params });
};
export const adminKeyWordSaveKeyWord = (params) => {
    return http.post("adminKeyWord/saveKeyWord", params);
};
export const adminKeyWordGetKeyWord = (params) => {
    return http.get("adminKeyWord/getKeyWord", { params: params });
};
export const adminKeyWordImportKeyword = (params) => {
    return http.post("adminKeyWord/importKeyword", params);
};
export const adminKeyWordGetExportKeyword = (params) => {
    return http.post("adminKeyWord/getExportKeyword", params);
};
export const adminKeyWordDelKeyWord = (params) => {
    return http.get("adminKeyWord/delKeyWord", { params: params });
};
export const theoryTopicEdit = (params) => {
    return http.post("theoryTopic/edit", params);
};
export const cate = (params) => {
    return http.get("cate", { params: params });
};
export const alCate = (params) => {
    return http.get("cate/alCate", { params: params });
};
export const getIdentifyCenterCateList = (params) => {
    return http.get("checkCenter/cateList", { params: params });
};
export const kefuListKefuData = (params) => {
    return http.get("kefu/listKefuData", { params: params });
};
export const kefuGetIC = () => {
    return http.get("kefu/getIC");
};
export const theoryTopicGetDetail = (params) => {
    return http.get("theoryTopic/getDetail", { params: params });
};
export const theoryTopicGetList = (params) => {
    return http.get("theoryTopic/getList", { params: params });
};
export const theoryTopicUploadRtImg = (params) => {
    return http.post("theoryTopic/uploadRtImg", params);
};
export const uploadSlice = (params) => {
    return newHttp.post('operationProblem/upload', params);
}
export const uploadMaterial = (params) => {
    return newHttp.post("operationProblem/uploadMaterial", params);
};
export const uploadVideo = (params) => {
    return newHttp.post("operationProblem/uploadVideo", params);
};
export const paperListPaper = (params) => {
    return http.get("paper/listPaper", { params: params });
};
export const paperSavePaper = (params) => {
    return http.post("paper/savePaper", params);
};
export const paperDelPaper = (params) => {
    return http.get("paper/delPaper", { params: params });
};
export const paperGetPaper = (params) => {
    return http.get("paper/getPaper", { params: params });
};
export const adminGoodUploadImage = (params) => {
    return http.post("adminGood/uploadImage", params);
};
export const adminGoodUploadImage1 = (params) => {
    return "adminGood/uploadImage";
};
export const adminGoodSaveGood = (params) => {
    return http.post("adminGood/saveGood", params);
};
export const adminGoodGetGood = (params) => {
    return http.get("adminGood/getGood", { params: params });
};
export const adminGoodDelGood = (params) => {
    return http.get("adminGood/delGood", { params: params });
};
export const theoryTopicExcel = (params) => {
    return http.post("theoryTopic/excel", params);
};
export const theoryTopicDel = (params) => {
    return http.post("theoryTopic/del", params);
};
export const kefuCreateSN = () => {
    return http.get("kefu/createSN");
};
export const operationProblemExamModuleList = () => {
    return http.get("operationProblem/examModuleList");
};
export const operationProblemSaveShopGoodsManagement = (params) => {
    return http.post("operationProblem/saveShopGoodsManagement", params);
};
export const operationProblemSaveCustomerService = (params) => {
    return http.post("operationProblem/saveCustomerService", params);
};
export const keFuSaveKeFuData = (params) => {
    return http.post("kefu/saveKefuData", params);
};
export const keFuGetKeFuData = (params) => {
    return http.get("kefu/getKefuData", { params: params });
};
export const keFuDelKeFuData = (params) => {
    return http.get("kefu/delKefuData", { params: params });
};
export const SaveArticlePromote = (params) => {
    return http.post("operationProblem/saveArticlePromote", params);
};
export const SaveLiveBroadcast = (params) => {
    return http.post("operationProblem/saveLiveBroadcast", params);
};
export const theoryTestPaperGetList = (params) => {
    return http.get("theoryTestPaper/getList", { params: params });
};
export const theoryTestPaperFirstPage = (params) => {
    return http.get("theoryTestPaper/firstPage", { params: params });
};
export const operationProblemSaveDataCollectGoods = (params) => {
    return http.post("operationProblem/saveDataCollectGoods", params);
};
export const operationProblemSaveDataCollect = (params) => {
    return http.post("operationProblem/saveDataCollect", params);
};
export const operationProblemDataCollectGoodsDetail = (params) => {
    return http.get("operationProblem/dataCollectGoodsDetail", {
        params: params,
    });
};
export const operationProblemDataCollectDelGoods = (params) => {
    return http.get("operationProblem/dataCollectDelGoods", { params: params });
};
export const customerInfoGetModuleList = (params) => {
    return http.get("customerInfo/getModuleList", { params: params });
};
export const operationProblemGoodsSettings = (params) => {
    return http.post("operationProblem/goodsSettings", params);
};
export const operationProblemDelMaterial = (params) => {
    return http.get("operationProblem/delMaterial", { params: params });
};
export const operationProblemOpList = (params) => {
    return http.get("operationProblem/opList", { params: params });
};
export const operationProblemSaveOrderManagement = (params) => {
    return http.post("operationProblem/saveOrderManagement", params);
};
export const operationProblemGetOperation = (params) => {
    return http.get("operationProblem/getOperation", { params: params });
};
export const newOperationProblemGetOperation = (params) => {
    return http.get("operationProblem/getOperationKuajing", { params: params });
};
export const operationProblemOrderStatus = () => {
    return http.get("operationProblem/orderStatus");
};
export const customerInfoEdit = (params) => {
    return http.post("customerInfo/edit", params);
};
export const customerInfoExcelInto = (params) => {
    return http.post("customerInfo/excelInto", params);
};
export const operationProblemUploadLbGoodsImg = (params) => {
    return http.post("operationProblem/uploadLbGoodsImg", params);
};
export const customerInfoGetDetail = (params) => {
    return http.get("customerInfo/getDetail", { params: params });
};
export const customerInfoDel = (params) => {
    return http.post("customerInfo/del", params);
};
export const customerInfoExcelOut = () => {
    return http.get("customerInfo/excelOut");
};
export const customerInfoGerExcelData = (params) => {
    return http.post("customerInfo/gerExcelData", params);
};
export const theoryTestPaperEdit = (params) => {
    return http.post("theoryTestPaper/edit", params);
};
export const theoryTestPaperSecondPage = (params) => {
    return http.get("theoryTestPaper/secondPage", { params: params });
};
export const theoryTestPaperDel = (params) => {
    return http.post("theoryTestPaper/del", params);
};
export const operationProblemOpEpList = (params) => {
    return http.get("operationProblem/opEpList", { params: params });
};
export const operationProbleList = (params) => {
    return http.get("operationProblem/opEpAllList", { params: params });
};
export const examLevels = () => {
    return http.get("operationProblem/examModuleList");
};
export const operationProblemEpOpList = (params) => {
    return http.get("operationProblem/epOpList", { params: params });
};
export const operationProblemSaveOpExamPapers = (params) => {
    return http.post("operationProblem/saveOpExamPapers", params);
};
export const operationProblemOpEpDetail = (params) => {
    return http.get("operationProblem/opEpDetail", { params: params });
};
export const operationProblemOpEpDel = (params) => {
    return http.get("operationProblem/opEpDel", { params: params });
};
export const probabilityGetList = (params) => {
    return http.get("probability/getList", { params: params });
};
export const probabilityGetDetail = (params) => {
    return http.get("probability/getDetail", { params: params });
};
export const probabilityOpen = (params) => {
    return http.get("probability/open", { params: params });
};
export const probabilityClose = (params) => {
    return http.get("probability/close", { params: params });
};
export const probabilityEdit = (params) => {
    return http.post("probability/edit", params);
};
export const probabilityDel = (params) => {
    return http.post("probability/del", params);
};
export const getTeacherList = (params) => {
    return http.get("teacher", { params: params });
};
export const teacherAdd = (params) => {
    return http.post("teacher/add", params);
};
export const teacherInfo = (id) => {
    return http.get("teacher/info/" + id);
};
export const teacherDel = (id) => {
    return http.get("teacher/del/" + id);
};
export const examGetTheoryByLevel = (params) => {
    return http.get("exam/getTheoryByLevel", { params: params });
};
export const examRandPaper = (params) => {
    return http.get("exam/randPaper", { params: params });
};
export const examSave = (params) => {
    return http.post("exam/save", params);
};
export const examList = (params) => {
    return http.get("exam/list", { params: params });
};
export const examStu = (params) => {
    return http.get("exam/stu", { params: params });
};
export const examEnd = (params) => {
    return http.get("exam/end", { params: params });
};
export const examDel = (params) => {
    return http.get("exam/del", { params: params });
};
export const examEditTime = (params) => {
    return http.post("exam/edit", params);
};
export const examClass = () => {
    return http.get("exam/class");
};
export const getExam = (params) => {
    return http.get("exam", { params: params });
};
export const getJudgeList = (params) => {
    return http.get("judge", { params: params });
};
export const judgeAdd = (params) => {
    return http.post("judge/add", params);
};
export const judgeInfo = (id) => {
    return http.get("judge/info/" + id);
};
export const judgeDel = (id) => {
    return http.get("judge/del/" + id);
};
export const getClassesList = (params) => {
    return http.get("classes", { params: params });
};
export const classesAdd = (params) => {
    return http.post("classes/add", params);
};
export const classesInfo = (id) => {
    return http.get("classes/info/" + id);
};
export const classesDel = (id) => {
    return http.get("classes/del/" + id);
};
export const studentAdd = (params) => {
    return http.post("student/add", params);
};
export const getStudentList = (params) => {
    return http.get("student", { params: params });
};
export const studentInfo = (id) => {
    return http.get("student/info/" + id);
};
export const studentDel = (id) => {
    return http.get("student/del/" + id);
};
export const cateCates = (params) => {
    return http.get("cate/cates", { params: params });
};
export const course = (params) => {
    return http.get("course", { params: params });
};
export const courseList = (params) => {
    return http.get("course/list", { params: params });
};
export const courseDel = (params) => {
    return http.get("course/del", { params: params });
};
export const getSchoolCourse = (params) => {
    return http.get("course/getSchoolCourse", { params: params });
};
export const getTeaSchoolCourse = (params) => {
    return http.get("course/teaSchoolCourse", { params: params });
};
export const courseSave = (params) => {
    return http.post("course/save", params);
};
export const courseUploadImage = (params) => {
    return http.post("course/uploadImage", params);
};
export const trainTheoryListTeacher = (params) => {
    return http.get("train/theoryListTeacher", { params: params });
};
export const trainTheoryListStudent = (params) => {
    return http.get("train/theoryListStudent", { params: params });
};
export const trainTheoryDetailsTeacher = (params) => {
    return http.get("train/theoryDetailsTeacher", { params: params });
};
export const stuOpGetExamInfo = (params) => {
    return http.get("stuOp/getExamInfo", { params: params });
};
export const examStuExamPage = () => {
    return http.get("exam/stuExamPage");
};
export const stuOpOpTrainList = (params) => {
    return http.get("stuOp/opTrainList", { params: params });
};
export const stuOpStuOpFreightTemplate = (params) => {
    return http.get("stuOp/stuOpFreightTemplate", { params: params });
};
export const stuOpSaveGoods = (params) => {
    return http.post("stuOp/saveGoods", params);
};
export const operationProblemUploadGoodsImg = (params) => {
    return http.post("operationProblem/uploadGoodsImg", params);
};
export const examStuExamKeyword = (params) => {
    return http.get("exam/stuExamKeyword", { params: params });
};
export const stuOpStuOpGoodsDetail = (params) => {
    return http.get("stuOp/stuOpGoodsDetail", { params: params });
};
export const stuOpStuOpGoodsList = (params) => {
    return http.get("stuOp/stuOpGoodsList", { params: params });
};
export const termsList = () => {
    return http.get("terms/list");
};
export const groupList = (params) => {
    return http.get("group/list", { params: params });
};
export const termsSearch = (params) => {
    return http.get("terms/search", { params: params });
};
export const termsImport = (params) => {
    return http.post("terms/import", params);
};
export const groupSave = (params) => {
    return http.post("group/save", params);
};
export const groupDel = (params) => {
    return http.get("group/del", { params: params });
};
export const termsDel = (params) => {
    return http.get("terms/del", { params: params });
};
export const groupOperate = (params) => {
    return http.get("group/operate", { params: params });
};
export const termsOperate = (params) => {
    return http.get("terms/operate", { params: params });
};
export const termsSave = (params) => {
    return http.post("terms/save", params);
};
export const termsUpload = (params) => {
    return "terms/upload";
};
export const assignTopics = (params) => {
    return http.get("assign/topics", { params: params });
};
export const assignTopic = (params) => {
    return http.post("assign/topic", params);
};
export const assignExampapers = () => {
    return http.get("assign/exampapers");
};
export const assignPapers = (params) => {
    return http.get("assign/papers", { params: params });
};
export const assignPaper = (params) => {
    return http.post("assign/paper", params);
};
export const trainResetAnswerTheoryPaper = (params) => {
    return http.get("train/resetAnswerTheoryPaper", { params: params });
};
export const assignList = () => {
    return http.get("assign/list");
};
export const assignCourses = (params) => {
    return http.get("assign/courses", { params: params });
};
export const assignCourse = (params) => {
    return http.post("assign/course", params);
};
export const getKefuInfo = () => {
    return http.get("kefu");
};
export const stuOpSaveFreightTemplate = (params) => {
    return http.post("stuOp/saveFreightTemplate", params);
};
export const stuOpStuFreightTemplateDel = (params) => {
    return http.get("stuOp/stuFreightTemplateDel", { params: params });
};

export const templateStudentXlsx = () => {
    return http.get("template/student.xlsx");
};
export const studentImport = (params) => {
    return http.post("student/import", params);
};
export const resourceType = () => {
    return http.get("resource/type");
};
export const resource = (params) => {
    return http.get("resource", { params: params });
};
export const resourceAdd = (params) => {
    return http.post("resource/add", params);
};
export const getDataByCourseID = (params) => {
    return http.get("course/getDataByCourseID", { params: params });
};
export const stuOpStuOpGoodsAttribute = (params) => {
    return http.get("stuOp/stuOpGoodsAttribute", { params: params });
};
export const operationProblemStoreSettings = (params) => {
    return http.post("operationProblem/storeSettings", params);
};
export const stuOpStuOptimizeTitle = (params) => {
    return http.post("stuOp/stuOptimizeTitle", params);
};
export const stuOpStuOpGoodsDel = (params) => {
    return http.get("stuOp/stuOpGoodsDel", { params: params });
};

export const deleteResource = (id) => {
    return http.get("resource/del/" + id);
};
export const resourceInfo = (id) => {
    return http.get("resource/info/" + id);
};
export const kefuGood = (params) => {
    return http.get("kefu/good", { params: params });
};
export const examTeaOperationList = (params) => {
    return http.get("exam/teaOperationList", { params: params });
};
export const examTeaOperationDetail = (params) => {
    return http.get("exam/teaOperationDetail", { params: params });
};
export const stuOpStuOpHotGoodsList = (params) => {
    return http.get("stuOp/stuOpHotGoodsList", { params: params });
};
export const kefuSubmit = (params) => {
    return http.post("kefu/submit", params);
};
export const theoryexamStartTheoryExam = () => {
    return http.get("theoryexam/startTheoryExam");
};
export const stuOpStuOpHotGoodsDetail = (params) => {
    return http.get("stuOp/stuOpHotGoodsDetail", { params: params });
};
export const kefuStatus = (params) => {
    return http.get("kefu/status", { params: params });
};
export const kefuHistorical = () => {
    return http.get("kefu/historical");
};
export const kefuSubmitEven = (params) => {
    return http.post("kefu/submitEven", params);
};
export const theoryexamSubmitTheoryExam = (params) => {
    return http.post("theoryexam/submitTheoryExam", params);
};
export const trainStartAnswerTheoryPaper = (params) => {
    return http.get("train/startAnswerTheoryPaper", { params: params });
};
export const trainSubmitAnswerTheoryPaper = (params) => {
    return http.post("train/submitAnswerTheoryPaper", params);
};
export const trainExitAnswerTheoryPaper = (params) => {
    return http.post("train/exitAnswerTheoryPaper", params);
};
export const stuOpOrderList = (params) => {
    return http.get("stuOp/stuOpOrderList", { params: params });
};
export const stuOpOrderOperation = (params) => {
    return http.get("stuOp/stuOpOrderOperation", { params: params });
};
export const newStuOpOrderOperation = (params) => {
    return http.get("stuOp/stuOpOrderOperationKuajing", { params: params });
};
export const stuOpModifyOrder = (params) => {
    return http.post("stuOp/stuOpModifyOrder", params);
};
export const kefuDrill = (params) => {
    return http.get("kefu_drill", { params: params });
};
export const markGetMarkListJudge = (params) => {
    return http.get("mark/getMarkListJudge", { params: params });
};
export const markGetNoMarkListJudge = (params) => {
    return http.get("mark/getNoMarkListJudge", { params: params });
};
export const markGetNoSubmitListJudge = (params) => {
    return http.get("mark/getNoSubmitListJudge", { params: params });
};
export const markStartMarkJudge = (params) => {
    return http.get("mark/startMarkJudge", { params: params });
};
export const courseApplyTemplate = (params) => {
    return http.post("course/applyTemplate", params);
};
export const courseSaveTemplate = (params) => {
    return http.post("course/saveTemplate", params);
};
export const courseTemplateList = (params) => {
    return http.get("course/templateList", { params: params });
};
export const courseGetTemplateData = (params) => {
    return http.get("course/getTemplateData", { params: params });
};
export const courseDelTemplate = (params) => {
    return http.get("course/delTemplate", { params: params });
};
export const kefu_drillStatus = (params) => {
    return http.get("kefu_drill/status", { params: params });
};
export const kefu_drillSubmit = (params) => {
    return http.post("kefu_drill/submit", params);
};
export const kefu_drillHistorical = (params) => {
    return http.get("kefu_drill/historical", { params: params });
};
export const kefu_drillSubmitEven = (params) => {
    return http.post("kefu_drill/submitEven", params);
};
export const kefu_drillGood = (params) => {
    return http.get("kefu_drill/good", { params: params });
};
export const kefu_drillTrainingRecord = (params) => {
    return http.get("kefu_drill/trainingRecord", { params: params });
};
export const markSubmitMarkJudge = (params) => {
    return http.post("mark/submitMarkJudge", params);
};
export const markGenerateReport = (params) => {
    return http.get("mark/generateReport", { params: params });
};
export const markGetMarkListTeacher = (params) => {
    return http.get("mark/getMarkListTeacher", { params: params });
};
export const markGetNoMarkListTeacher = (params) => {
    return http.get("mark/getNoMarkListTeacher", { params: params });
};
export const markGetNoSubmitListTeacher = (params) => {
    return http.get("mark/getNoSubmitListTeacher", { params: params });
};
export const markStartMarkTeacher = (params) => {
    return http.get("mark/startMarkTeacher", { params: params });
};
export const markSubmitMarkTeacher = (params) => {
    return http.post("mark/submitMarkTeacher", params);
};
export const personalAdd = (params) => {
    return http.post("personal/add", params);
};
export const personalList = (params) => {
    return http.get("personal", { params: params });
};
export const personalDel = (id) => {
    return http.get("personal/del/" + id);
};
export const examGradingManagementList = (params) => {
    return http.get("exam/gradingManagementList", { params: params });
};
export const certificateCertificateTemplate = (params) => {
    return http.post("certificate/certificate_template", params);
};
export const certificateTemplateList = (params) => {
    return http.get("certificate/certificateTemplateList", { params: params });
};
export const certificateShowTemplate = (params) => {
    return http.get("certificate/certificate_show_template", { params: params });
};
export const delCertificateTemplate = (params) => {
    return http.post("certificate/del_certificate_template", params);
};
export const examExamList = (params) => {
    return http.get("exam/examList", { params: params });
};
export const examExamStuList = (params) => {
    return http.get("exam/examStuList", { params: params });
};
export const examExportStuScore = (params) => {
    return http.get("exam/exportStuScore", { params: params });
};
export const theoryexamAnswerDetail = (params) => {
    return http.get("theoryexam/answerDetail", { params: params });
};
export const schoolReportManagementList = (params) => {
    return http.get("exam/schoolReportManagementList", { params: params });
};
export const reportManagementStuList = (params) => {
    return http.get("exam/reportManagementStuList", { params: params });
};
export const operationProblemOpDetail = (params) => {
    return http.get("operationProblem/opDetail", { params: params });
};
export const operationProblemOpDel = (params) => {
    return http.get("operationProblem/opDel", { params: params });
};
export const theoryexamMarkDetail = (params) => {
    return http.get("theoryexam/markDetail", { params: params });
};
export const examGetPaperByLevel = (params) => {
    return http.get("exam/getPaperByLevel", { params: params });
};
export const examStuKefuExamDetail = (params) => {
    return http.get("exam/stuKefuExamDetail", { params: params });
};
export const certificateInfo = (params) => {
    return http.post("certificate/certificate_info", params);
};
export const resetKefuTrain = (params) => {
    return http.get("kefu_drill/stuResetDrill", { params: params });
};
export const certificateWhetherTemplate = (params) => {
    return http.get("certificate/whetherTemplate", { params: params });
};
export const certificateGetExamList = (params) => {
    return http.get("certificate/getExamList", { params: params });
};
export const getStudentCertificateList = (params) => {
    return http.post("certificate/getStudentCertificateList", params);
};
export const getNOStudentCertificateList = (params) => {
    return http.post("certificate/getNOStudentCertificateList", params);
};
export const getClassName = (params) => {
    return http.get("certificate/getClassName", { params: params });
};
export const delCertificate = (params) => {
    return http.post("certificate/del_certificate", params);
};
export const certificateTemplate = (params) => {
    return http.get("certificate/template", { params: params });
};
export const studentCertificate = (params) => {
    return http.get("certificate/studentCertificate", { params: params });
};
export const certificateEditTemplate = (params) => {
    return http.post("certificate/certificate_edit_template", params);
};
export const createERCode = (params) => {
    return http.get("certificate/createERCode", { params: params });
};
export const trainAnswerDetailListTeacher = (params) => {
    return http.get("train/answerDetailListTeacher", { params: params });
};
export const trainAnswerDetailTeacher = (params) => {
    return http.get("train/answerDetailTeacher", { params: params });
};
export const reportManagementList = (params) => {
    return http.get("exam/reportManagementList", { params: params });
};
export const examSeeStuReport = (params) => {
    return http.get("exam/seeStuReport", { params: params });
};
export const shopManagementDetail = (params) => {
    return http.get("teacherop/shopManagementDetail", { params: params });
};
export const examGetExamRate = (params) => {
    return http.get("exam/getExamRate", { params: params });
};
export const trainAnswerDetailListStudent = (params) => {
    return http.get("train/answerDetailListStudent", { params: params });
};
export const trainAnswerDetailStudent = (params) => {
    return http.get("train/answerDetailStudent", { params: params });
};
export const theoryTestPaperGetNameByCategory = (params) => {
    return http.get("theoryTestPaper/getNameByCategory", { params: params });
};
export const decorationIndex = (params) => {
    return http.get("decoration/index", { params: params });
};
export const decorationUpload = (params) => {
    return http.post("decoration/upload", params);
};
export const decoration = (params) => {
    return http.post("decoration", params);
};
export const decorationDetail = (params) => {
    return http.post("decoration/detail", params);
};
export const decorationDiy = (params) => {
    return http.post("decoration/diy", params);
};
export const collectSave_img = (params) => {
    return http.post("collect/save_img", params);
};
export const collectUp_video = (params) => {
    return newHttp.post("collect/up_video", params);
};
export const collectMerge = (params) => {
    return newHttp.post("collect/merge", params);
};
export const collectSave_video = (params) => {
    return http.post("collect/save_video", params);
};
export const teacheropGoodsImgAndVideoList = (params) => {
    return http.get("teacherop/goodsImgAndVideoList", { params: params });
};
export const collectVideo = (params) => {
    return http.get("collect/video", { params: params });
};
export const collectImg = (params) => {
    return http.get("collect/img", { params: params });
};
export const batchCreateCertificate = (params) => {
    return http.post("certificate/batchCreateCertificate", params);
};
export const batchPrintCertificate = (params) => {
    return http.get("certificate/batchPrintCertificate", { params: params });
};
export const storesettingInfo = (params) => {
    return http.get("storesetting/info", { params: params });
};
export const storesettingAdd = (params) => {
    return http.post("storesetting/add", params);
};
export const storesettingUpload = (params) => {
    return http.post("storesetting/upload", params);
};
export const teacheropNetworkPromotionDetail = (params) => {
    return http.get("teacherop/networkPromotionDetail", { params: params });
};
export const networkMarketingDetail = (params) => {
    return http.get("teacherop/networkMarketingDetail", { params: params });
};
export const stuOpArticleDetail = (params) => {
    return http.get("stuOp/articleDetail", { params: params });
};
export const stuOpArticleList = (params) => {
    return http.get("stuOp/articleList", { params: params });
};
export const stuOpSaveArticle = (params) => {
    return http.post("stuOp/saveArticle", params);
};
export const stuOpArticleDel = (params) => {
    return http.get("stuOp/articleDel", { params: params });
};
export const stuOpUserAnalysisDetail = (params) => {
    return http.get("stuOp/userAnalysisDetail", { params: params });
};
export const stuOpSaveUserAnalysis = (params) => {
    return http.post("stuOp/saveUserAnalysis", params);
};
export const stuOpSaveVideoPromote = (params) => {
    return http.post("stuOp/saveVideoPromote", params);
};
export const stuOpVideoPromoteDetail = (params) => {
    return http.get("stuOp/videoPromoteDetail", { params: params });
};
export const stuOpDataAnalysis = (params) => {
    return http.get("stuOp/dataAnalysis", { params: params });
};
export const teacheropTeacherGoodsScore = (params) => {
    return http.post("teacherop/teacherGoodsScore", params);
};
export const storesettingTwo = (params) => {
    return http.get("storesetting/two", { params: params });
};
export const storesettingAddStepTwo = (params) => {
    return http.post("storesetting/add_step_two", params);
};
export const storesettingThree = (params) => {
    return http.get("storesetting/three", { params: params });
};
export const storesettingAddStepThree = (params) => {
    return http.post("storesetting/add_step_three", params);
};
export const stuOpOpResetTrain = (params) => {
    return http.get("stuOp/opResetTrain", { params: params });
};
export const stuOpSaveLive = (params) => {
    return http.post("stuOp/saveLive", params);
};
export const stuOpLiveList = (params) => {
    return http.get("stuOp/liveList", { params: params });
};
export const stuOpLiveDel = (params) => {
    return http.get("stuOp/liveDel", { params: params });
};
export const stuOpLiveDetail = (params) => {
    return http.get("stuOp/liveDetail", { params: params });
};
export const stuOpLiveGoodsList = (params) => {
    return http.get("stuOp/liveGoodsList", { params: params });
};
export const stuOpLiveGoodsDetail = (params) => {
    return http.get("stuOp/liveGoodsDetail", { params: params });
};
export const stuOpLiveGoodsDel = (params) => {
    return http.get("stuOp/liveGoodsDel", { params: params });
};
export const stuOpSaveLiveGoods = (params) => {
    return http.post("stuOp/saveLiveGoods", params);
};
export const stuOpGoodsManagementList = (params) => {
    return http.get("stuOp/goodsManagementList", { params: params });
};
export const stuOpGoodsManagementDel = (params) => {
    return http.get("stuOp/goodsManagementDel", { params: params });
};
export const stuOpSaveGoodsManagement = (params) => {
    return http.post("stuOp/saveGoodsManagement", params);
};
export const stuOpDrawList = (params) => {
    return http.get("stuOp/drawList", { params: params });
};
export const stuOpDrawDetail = (params) => {
    return http.get("stuOp/drawDetail", { params: params });
};
export const stuOpDrawDel = (params) => {
    return http.get("stuOp/drawDel", { params: params });
};
export const stuOpSaveDraw = (params) => {
    return http.post("stuOp/saveDraw", params);
};
export const storeinfoInfo = (params) => {
    return http.get("storeinfo/info", { params: params });
};
export const storeinfoAdd = (params) => {
    return http.post("storeinfo/add", params);
};
export const storeinfoName = (params) => {
    return http.get("storeinfo/name", { params: params });
};
export const storeinfoAddname = (params) => {
    return http.post("storeinfo/addname", params);
};
export const teacheropGoodsInfoCollectDetail = (params) => {
    return http.get("teacherop/goodsInfoCollectDetail", { params: params });
};
export const teacheropTeacherScoreDetail = (params) => {
    return http.get("teacherop/teacherScoreDetail", { params: params });
};
export const termsGet = (params) => {
    return http.get("terms/get", { params: params });
};
export const stuOpLiveConsole = (params) => {
    return http.get("stuOp/liveConsole", { params: params });
};
export const stuOpGoodsPush = (params) => {
    return http.get("stuOp/goodsPush", { params: params });
};
export const stuOpDrawPush = (params) => {
    return http.get("stuOp/drawPush", { params: params });
};
export const storeinfoSetting = (params) => {
    return http.get("storeinfo/setting", { params: params });
};
export const storeinfoSet = (params) => {
    return http.post("storeinfo/set", params);
};
export const storeinfoMain = (params) => {
    return http.get("storeinfo/main", { params: params });
};
export const teacheropTrainData = (params) => {
    return http.get("teacherop/trainData", { params: params });
};
export const stuOpCustomerInfoList = (params) => {
    return http.get("stuOp/customerInfoList", { params: params });
};
export const stuOpSaveCustomerInfo = (params) => {
    return http.post("stuOp/saveCustomerInfo", params);
};
export const stuOpCustomerInfoDetail = (params) => {
    return http.get("stuOp/customerInfoDetail", { params: params });
};
export const stuOpDelCustomerInfo = (params) => {
    return http.get("stuOp/delCustomerInfo", { params: params });
};
export const stuOpGetCustomerInfoExcel = (params) => {
    return http.post("stuOp/getCustomerInfoExcel", params);
};
export const stuOpStuOpCustomerInfo = (params) => {
    return http.get("stuOp/stuOpCustomerInfo", { params: params });
};
export const stuOpExportCustomerInfo = (params) => {
    return http.get("stuOp/exportCustomerInfo", { params: params });
};
export const stuOpStuOpCustomerInfoDetail = (params) => {
    return http.get("stuOp/stuOpCustomerInfoDetail", { params: params });
};
export const stuOpStuOpDataCollectGoodsList = (params) => {
    return http.get("stuOp/stuOpDataCollectGoodsList", { params: params });
};
export const stuOpPurchaseGoods = (params) => {
    return http.get("stuOp/purchaseGoods", { params: params });
};
export const stuOpStuOpDataCollectGoodsDetail = (params) => {
    return http.get("stuOp/stuOpDataCollectGoodsDetail", { params: params });
};
export const nextCertificate = (params) => {
    return http.get("certificate/nextCertificate", { params: params });
};
export const stuOpOpTrainNumList = (params) => {
    return http.get("stuOp/opTrainNumList", { params: params });
};
export const stuOpTrainData = (params) => {
    return http.get("stuOp/trainData", { params: params });
};
export const fanList = (params) => {
    return http.get("fan/list", { params: params });
};
export const sactionList = (params) => {
    return http.get("saction/list", { params: params });
};
export const sactionMark = (params) => {
    return http.post("saction/mark", params);
};
export const teacheropNetworkPromotionList = (params) => {
    return http.get("teacherop/networkPromotionList", { params: params });
};
export const teacheropTeacherScore = (params) => {
    return http.post("teacherop/teacherScore", params);
};
export const fanSave = (params) => {
    return http.post("fan/save", params);
};
export const fanImport = (params) => {
    return http.post("fan/import", params);
};
export const fanExport = (params) => {
    return http.get("fan/export", { params: params });
};
export const fan = (params) => {
    return http.get("fan", { params: params });
};
export const fanDel = (params) => {
    return http.get("fan/del", { params: params });
};
export const fan1 = (params) => {
    return http.post("fan", params);
};
export const sactionExamDetail = (params) => {
    return http.get("saction/exam_detail", { params: params });
};
export const operationProblemSaveDataClean = (params) => {
    return http.post("operationProblem/saveDataClean", params);
};
export const cleanAdd = (params) => {
    return http.get("clean/add", { params: params });
};
export const clean_drillAdd = (params) => {
    return http.get("clean_drill/add", { params: params });
};
export const cleanUpload = (params) => {
    return http.post("clean/upload", params);
};
export const clean_drillUpload = (params) => {
    return http.post("clean_drill/upload", params);
};
export const teacheropTrainCustomerStuAnswer = (params) => {
    return http.get("teacherop/trainCustomerStuAnswer", { params: params });
};
export const teacheropTrainCleanAnswer = (params) => {
    return http.get("teacherop/trainCleanAnswer", { params: params });
};
export const teacheropStoreCustomerServiceDetail = (params) => {
    return http.get("teacherop/storeCustomerServiceDetail", { params: params });
};
export const teacheropCustomerAnswer = (params) => {
    return http.get("teacherop/customerAnswer", { params: params });
};
export const teacheropCustomerStuAnswer = (params) => {
    return http.get("teacherop/customerStuAnswer", { params: params });
};
export const teacheropCleanAnswer = (params) => {
    return http.get("teacherop/cleanAnswer", { params: params });
};
export const teacherdataClassExamDataAll = (params) => {
    return http.get("teacherdata/classExamDataAll", { params: params });
};
export const teacherdataSingleClassData = (params) => {
    return http.get("teacherdata/singleClassData", { params: params });
};
export const IdentifyCenterCreate = (params) => {
    return http.post("checkCenter/add", params);
};
export const IdentifyCenterDelete = (params) => {
    return http.post("checkCenter/del", params);
};
export const IdentifyCenterList = (params) => {
    return http.get("checkCenter/list", { params: params });
};
export const IdentifyAccountList = (params) => {
    return http.get("checkCenter/listManage", { params: params });
};
export const IdentifyAccountOperate = (params) => {
    return http.post("checkCenter/saveManage", params);
};
export const IdentifyAccountDelete = (params) => {
    return http.post("checkCenter/delManage", params);
};
export const checkCenterLogin = (params) => {
    return http.post("checkCenterLogin", params);
};
export const showJudgeList = (params) => {
    return http.get("checkCenter/judgeList", { params: params });
};
export const addJudge = (params) => {
    return http.post("checkCenter/judgeSave", params);
};
// 评委列表删除
export const delJudge = (params) => {
    return http.post("checkCenter/judgeDel", params);
};
//考生管理-考生分类获取列表
export const studentClassificationData = (params) => {
    return http.get("testeeCategory/getList", { params: params });
};
//考生管理-考生分类新增和编辑
export const addClassifyData = (params) => {
    return http.post("testeeCategory/edit", params);
};
//考生管理-考生分类删除
export const delClassify = (params) => {
    return http.post("/testeeCategory/del", params);
};
//考生管理-考场和考试时间
export const studenttime = (params) => {
    return http.get("testee/roomData", { params: params });
};
//考生管理-考试等级
export const studentlevel = (params) => {
    return http.get("testee/getExamLevel", { params: params });
};
//考生管理列表
export const examineeList = (params) => {
    return http.get("testee/getList", { params: params });
};
//考生管理-添加学生
export const addexaminee = (params) => {
    return http.post("testee/edit", params);
};
//考生管理-学生详情
export const particularsList = (params) => {
    return http.get("testee/getDetail", { params: params });
};
//考试管理-删除
export const delstudentLis = (params) => {
    return http.post("testee/delTestee", params);
};
//鉴定中心-学生考试页面
export const getAuthen = (params) => {
    return http.get("exam_info", { params: params });
};
//考生管理下载模板
export const download = (params) => {
    return http.get("testee/downloadTesteeTem", { params: params });
};
//考生管理导出学生
export const deriveStudent = (params) => {
    return http.get("testee/exportTestee", { params: params });
};
//考生管理导入学生
export const leadStudent = (params) => {
    return http.post("testee/importTestee", params);
};
export const checkCenterLoginOut = (params) => {
    return http.post("checkCenter/checkCenterLoginOut", params);
};

export const addExam = (params) => {
    return http.post("e_manage/add", params);
};

export const showExam = (params) => {
    return http.get("e_manage", { params: params });
};

export const delExam = (params) => {
    return http.post("e_manage/del", params);
};

export const lookExam = (params) => {
    return http.get("e_manage/info", { params: params });
};

export const updateExam = (params) => {
    return http.post("e_manage/update", params);
};

export const showApproval = (params) => {
    return http.get("apply_message", { params: params });
};

export const editApproval = (params) => {
    return http.post("apply_message/edit", params);
};

export const addApply = (params) => {
    return http.post("apply/apply", params);
};

export const getIdentifyCenterTheoryList = (params) => {
    return http.get("al_theory_topic/getList", { params: params });
};

export const showExamList = (params) => {
    return http.get("examination", { params: params });
};

export const showExaminatinRoom = (params) => {
    return http.get("examination/room", { params: params });
};

export const examStudentList = (params) => {
    return http.get("examination/category", { params: params });
};

export const showExamStudentList = (params) => {
    return http.get("examination/student_list", { params: params });
};

export const showExamJudgeList = (params) => {
    return http.get("examination/judge", { params: params });
};

export const showPaperList = (params) => {
    return http.get("examination/paper", { params: params });
};

export const createExamData = (params) => {
    return http.post("examination/create", params);
};

export const delExamData = (params) => {
    return http.post("examination/del", params);
};

export const editExamData = (params) => {
    return http.get("examination/info", { params: params });
};

export const showExamStudentData = (params) => {
    return http.get("examination/exam_detail", { params: params });
};

export const delExamStudentData = (params) => {
    return http.post("examination/del_student", params);
};

export const identifyCenterAssignTheory = (params) => {
    return http.post("checkCenter/assignTopic", params);
};

export const identifyCenterAssignActual = (params) => {
    return http.post("checkCenter/operationTopic", params);
};

export const identifyCenterAssignPaper = (params) => {
    return http.post("checkCenter/assignPaper", params);
};

export const getIdentifyCenterAssignTheoryQuestionList = (params) => {
    return http.get("checkCenter/assignTopicList", { params: params });
};

export const getIdentifyCenterAssignActualQuestionList = (params) => {
    return http.get("checkCenter/operationTopicList", { params: params });
};

export const getIdentifyCenterAssignPaperList = (params) => {
    return http.get("checkCenter/assignPaperList", { params: params });
};

export const getIdentifyCenterActualPaperCascade = (params) => {
    return http.get("checkCenter/getOpExamPaperList", { params: params });
};

export const getIdentifyCenterTheoryPaperCascade = (params) => {
    return http.get("checkCenter/getTheoryPaperList", { params: params });
};

export const getIdentifyCenterTheoryDetail = (params) => {
    return http.get("al_theory_paper/getDetail", { params: params });
};

export const getIdentifyCenterTheoryQuestionDetail = (params) => {
    return http.get("al_theory_topic/getDetail", { params: params });
};

export const identifyOperateActualPaper = (params) => {
    return http.post("al_theory_paper/edit", params);
};

export const getIdentifyActualDetailModuleData = (params) => {
    return http.get("checkCenter/centerOptDetail", { params });
};

export const getIdentifyCenterTheoryPaperList = (params) => {
    return http.get("al_theory_paper/getList", { params: params });
};
export const getIdentifyCenterActualPaperList = (params) => {
    return http.get("checkCenter/optCenterList", { params: params });
};

export const deleteIdentifyCenterTheoryPaper = (params) => {
    return http.post("al_theory_paper/del", params);
};
export const deleteIdentifyCenterActualPaper = (params) => {
    return http.post("checkCenter/optCenterDel", params);
};
export const checkCenter_getCertificateList = (params) => {
    return http.get("checkCenter/getCertificateList", { params: params });
};
export const checkCenter_notGetCertificateList = (params) => {
    return http.get("checkCenter/notGetCertificateList", { params: params });
};
export const checkCenter_alreadyGetCertificateList = (params) => {
    return http.get("checkCenter/alreadyGetCertificateList", { params: params });
};
export const examination_data_index = (params) => {
    return http.get("examination/data_index", { params: params });
};
export const examination_student_score = (params) => {
    return http.get("examination/student_score", { params: params });
};
export const jding_detail = (params) => {
    return http.get("jding/detail", { params: params });
};
export const jding_info = (params) => {
    return http.get("jding/info", { params: params });
};
export const jding_save = (params) => {
    return http.post("jding/save", params);
};
export const al_theory_paper_getCategoryFifth = (params) => {
    return http.get("al_theory_paper/getCategoryFifth", { params: params });
};
export const certificate_createERCode = (params) => {
    return http.post("certificate/createERCode", params);
};
export const showPaperLevel = (params) => {
    return http.get("apply/examlevel", { params: params });
};
export const dataCentorTplAddServiceData = (params) => {
    return http.post('dataCentorTpl/addServiceData', params)
}
export const dataCentorTplGetServiceData = (params) => {
    return http.get('dataCentorTpl/getServiceData', { params: params })
}
export const dataCentorTplAddIndustryData = (params) => {
    return http.post('dataCentorTpl/addIndustryData', params)
}
export const dataCentorTplGetIndustryData = (params) => {
    return http.get('dataCentorTpl/getIndustryData', { params: params, headers: { hideMessage: true } })
}
export const dataCentorTplGetGoodsDropdown = (params) => {
    return http.get('dataCentorTpl/getGoodsDropdown', { params: params })
}
export const dataCentorTplAddCommodityData = (params) => {
    return http.post('dataCentorTpl/addCommodityData', params)
}
export const dataCentorTplCommodityList = (params) => {
    return http.get('dataCentorTpl/commodityList', { params: params })
}
export const dataCentorTplGetCommodityData = (params) => {
    return http.get('dataCentorTpl/getCommodityData', { params: params })
}
export const dataCentorTplDelCommodity = (params) => {
    return http.post('dataCentorTpl/delCommodity', params)
}
export const dataCentorTplGetRegion = (params) => {
    return http.get('dataCentorTpl/getRegion', { params: params })
}
export const advertisingList = (params) => {
    return http.post('advertising/list', params)
}
export const advertisingAdd = (params) => {
    return http.post('advertising/add', params)
}
export const customer_relations = (params) => {
    return http.get('customer_relations', { params: params })
}
export const customer_relations_save = (params) => {
    return http.post('customer_relations/save', params)
}
export const customerRelationsList = (params) => {
    return http.get('customer_relations/list', { params: params })
}
export const customerRelationsDel = (params) => {
    return http.get('customer_relations/del', { params: params })
}
export const advertisingEdit = (params) => {
    return http.post('advertising/edit', params)
}
export const advertisingInfo = (params) => {
    return http.get('advertising/info', { params: params })
}
export const advertisingImportData = (params) => {
    return http.post('advertising/importData', params)
}
export const advertisingSetCache = (params) => {
    return http.post('advertising/setCache', params)
}
export const advertisingDel = (params) => {
    return http.post('advertising/del', params)
}
export const dataCentorTplAddCustomerAnalysis = (params) => {
    return http.post('dataCentorTpl/addCustomerAnalysis', params)
}
export const dataCentorTplGetCustomerAnalysis = (params) => {
    return http.get('dataCentorTpl/getCustomerAnalysis', { params: params, headers: { hideMessage: true } })
}
export const collectionCollectionSettings = (params) => {
    return http.post('collection/collectionSettings', params)
}
export const operationPromote_getList = (params) => {
    return http.get('operationPromote/getList', { params: params })
}
export const operationPromote_getPosition = (params) => {
    return http.get('operationPromote/getPosition', { params: params })
}
export const operationPromote_del = (id) => {
    return http.get('operationPromote/del/' + id)
}
export const operationPromote_gData = (params) => {
    return http.post('operationPromote/gData', params)
}
export const operationPromote_getKeyWorkList = (params) => {
    return http.get('operationPromote/getKeyWorkList', { params: params })
}
export const operationPromote_getGoodsList = (params) => {
    return http.get('operationPromote/getGoodsList', { params: params })
}
export const operationPromote_saveOperationData = (params) => {
    return http.post('operationPromote/saveOperationData', params)
}
export const operationPromote_info = (id) => {
    return http.get('operationPromote/info/' + id)
}
export const operationProblemGetInformationList = (params) => {
    return http.get('operationProblem/getInformationList', { params: params })
}
export const operationProblemSavePromote = (params) => {
    return http.post('operationProblem/savePromote', params)
}
export const operationProblemGetInformationInfo = (id) => {
    return http.get('operationProblem/getInformationInfo/' + id)
}
export const search_engine_problem = (params) => {
    return http.post('search_engine/problem', params)
}
export const dataCentorTplAddSellerAnalysis = (params) => {
    return http.post('dataCentorTpl/addSellerAnalysis', params)
}
export const dataCentorTplGetSellerAnalysis = (params) => {
    return http.get('dataCentorTpl/getSellerAnalysis', { params: params, headers: { hideMessage: true } })
}
export const dataCentorTplGetDataCenterTpl = (params) => {
    return http.get('dataCentorTpl/getDataCenterTpl', { params: params })
}
export const dataCentorTplDelDataCenterTpl = (params) => {
    return http.post('dataCentorTpl/delDataCenterTpl', params)
}
export const customer_relations_problem = (params) => {
    return http.post('customer_relations/problem', params)
}
export const transactionGetArea = (params) => {
    return http.get('transaction/getArea', { params: params })
}
export const transactionAdminRepertoryCreate = (params) => {
    return http.post('transaction/adminRepertoryCreate', params)
}
export const transactionGetGoodsInfo = (params) => {
    return http.get('transaction/getGoodsInfo', { params: params })
}
export const grouppromotionSetting = (params) => {
    return http.post('grouppromotion/setting', params)
}
export const collectionGetCollection = (params) => {
    return http.post('collection/getCollection', params)
}
export const grouppromotionGetGroupInfo = (params) => {
    return http.post('grouppromotion/getGroupInfo', params)
}
export const tranPurchaseUpload = (params) => {
    return http.post('tranPurchase/upload', params)
}
export const tranPurchaseAdminPurchaseCreate = (params) => {
    return http.post('tranPurchase/adminPurchaseCreate', params)
}
export const transactionStuShipAddress = (params) => {
    return http.post('transaction/stuShipAddress', params)
}
export const transactionStuShipAddressList = (params) => {
    return http.get('transaction/stuShipAddressList', { params: params })
}
export const transactionStuShipAddressDel = (params) => {
    return http.get('transaction/stuShipAddressDel', { params: params })
}
export const transactionStuGoodsShow = (params) => {
    return http.get('transaction/stuGoodsShow', { params: params })
}
export const StuOpProGetBalance = (id) => {
    return http.get('StuOpPro/getBalance/' + id)
}
export const StuOpProGetResourcesData = (params) => {
    return http.get('StuOpPro/getResourcesData', { params: params })
}
export const StuOpProGetPeopleData = (params) => {
    return http.get('StuOpPro/getPeopleData', { params: params })
}
export const StuOpProGetLocation = (params) => {
    return http.get('StuOpPro/getLocation', { params: params })
}
export const operationsOperationsSettings = (params) => {
    return http.post('operations/OperationsSettings', params)
}
export const operationsGetOpInfo = (params) => {
    return http.post('operations/getOpInfo', params)
}
export const operationProblemSaveStoreVisualDesign = (params) => {
    return http.post('operationProblem/saveStoreVisualDesign', params)
}
export const StuOpProSavePlan = (params) => {
    return http.post('StuOpPro/savePlan', params)
}
export const StuOpProInfo = (params) => {
    return http.get('StuOpPro/info/' + params)
}
export const StuOpProGetPlanList = (params) => {
    return http.get('StuOpPro/getPlanList', { params: params })
}
export const StuOpProEditPromote = (params) => {
    return http.post('StuOpPro/editPromote', params)
}
export const StuOpProDel = (params) => {
    return http.get('StuOpPro/del/' + params)
}
export const StuOpProEnd = (params) => {
    return http.post('StuOpPro/end', params)
}
export const StuOpProGetEndNumber = (params) => {
    return http.get('StuOpPro/getEndNumber', { params: params })
}
export const drill_result_list = (params) => {
    return http.get('customer_relations/drill_result_list', { params: params })
}
export const see_drill_result = (params) => {
    return http.get('customer_relations/see_drill_result', { params: params })
}
export const del_drill_result = (params) => {
    return http.get('customer_relations/del_drill_result', { params: params })
}
export const save_drill_result = (params) => {
    return http.post('customer_relations/save_drill_result', params)
}
export const operationProblemSaveSocial = (params) => {
    return http.post('operationProblem/saveSocial', params)
}
export const operationProblemGetSocialInfo = (id) => {
    return http.get('operationProblem/getSocialInfo/' + id)
}
export const drill_data_map = (params) => {
    return http.get('customer_relations/drill_data_map', { params: params })
}
export const StuOpProDataTimeAnalysis = (params) => {
    return http.get('StuOpPro/dataTimeAnalysis', { params: params })
}
export const tranPurchaseGoodsManager = (params) => {
    return http.get('tranPurchase/goodsManager', { params: params })
}
export const transactionStuGoodsUpdate = (params) => {
    return http.get('transaction/stuGoodsUpdate', { params: params })
}
export const tranPurchaseStuSupplyInfo = (params) => {
    return http.get('tranPurchase/stuSupplyInfo', { params: params })
}
export const tranPurchaseGetMoney = (params) => {
    return http.get('tranPurchase/getMoney', { params: params })
}
export const tranPurchaseStuGoodsPurchase = (params) => {
    return http.post('tranPurchase/stuGoodsPurchase', params)
}
export const StuOpProGetLocationAnalysisData = (params) => {
    return http.get('StuOpPro/getLocationAnalysisData', { params: params })
}
export const exam_result_list = (params) => {
    return http.get('customer_relations/exam_result_list', { params: params })
}
export const see_exam_result = (params) => {
    return http.get('customer_relations/see_exam_result', { params: params })
}
export const del_exam_result = (params) => {
    return http.get('customer_relations/del_exam_result', { params: params })
}
export const save_exam_result = (params) => {
    return http.post('customer_relations/save_exam_result', params)
}
export const exam_data_map = (params) => {
    return http.get('customer_relations/exam_data_map', { params: params })
}
export const operationProblemCommerceProductionData = (params) => {
    return http.post('operationProblem/commerceProductionData', params)
}
export const operationProblemGetOneInfo = (params) => {
    return http.get('operationProblem/getOneInfo', { params: params })
}
export const reset_drill = (params) => {
    return http.get('customer_relations/reset_drill', { params: params })
}
export const drill_answer = (params) => {
    return http.get('customer_relations/drill_answer', { params: params })
}
export const transactionStuClientAnalyze = (params) => {
    return http.get('transaction/stuClientAnalyze', { params: params })
}
export const stuOpVisualDesignList = (params) => {
    return http.get('stuOp/visualDesignList', { params: params })
}
export const stuOpVisualDesignUploadImg = (params) => {
    return http.post('stuOp/visualDesignUploadImg', params)
}
export const stuOpSaveVisualDesign = (params) => {
    return http.post('stuOp/saveVisualDesign', params)
}
export const collectionSaveEaxmAnswer = (params) => {
    return http.post('collection/saveEaxmAnswer', params)
}
export const StuCommerceSavaAnswer = (params) => {
    return http.post('StuCommerce/savaAnswer', params)
}
export const collectionGetStuAnswer = (params) => {
    return http.post('collection/getStuAnswer', params)
}
export const collectionSaveStuCollectionAnswer = (params) => {
    return http.post('collection/saveStuCollectionAnswer', params)
}
export const dataCentorTplGetCustomerInfo = (params) => {
    return http.get('dataCentorTpl/getCustomerInfo', { params: params })
}
export const dataCentorTplGetCustomerInfoDetail = (params) => {
    return http.get('dataCentorTpl/getCustomerInfoDetail', { params: params })
}
export const StuCommerceGetList = (params) => {
    return http.get('StuCommerce/getList', { params: params, headers: { hideMessage: true } })
}
export const dataCentorTplGetIndustryDataExam = (params) => {
    return http.get('dataCentorTpl/getIndustryDataExam', { params: params })
}
export const dataCentorTplCommodityListExam = (params) => {
    return http.get('dataCentorTpl/commodityListExam', { params: params })
}
export const show_drill_stu_detail = (params) => {
    return http.get('customer_relations/show_drill_stu_detail', { params: params })
}
export const seeDrillStuDetail = (params) => {
    return http.get('customer_relations/seeDrillStuDetail', { params: params })
}
export const dataCentorTplGetCommodityDataExam = (params) => {
    return http.get('dataCentorTpl/getCommodityDataExam', { params: params })
}
export const StuOpSocialList = (params) => {
    return http.get('StuOpSocial/list', { params: params })
}
export const StuOpSocialGetSalesGoodsData = (params) => {
    return http.get('StuOpSocial/getSalesGoodsData', { params: params })
}
export const StuOpSocialSaveSocial = (params) => {
    return http.post('StuOpSocial/saveSocial', params)
}
export const StuOpSocialDel = (id) => {
    return http.get('StuOpSocial/del/' + id)
}
export const StuOpSocialInfo = (id) => {
    return http.get('StuOpSocial/info/' + id)
}
export const dataCentorTplGetCustomerAnalysisExam = (params) => {
    return http.get('dataCentorTpl/getCustomerAnalysisExam', { params: params })
}
export const dataCentorTplGetSellerAnalysisExam = (params) => {
    return http.get('dataCentorTpl/getSellerAnalysisExam', { params: params })
}
export const show_exam_stu_detail = (params) => {
    return http.get('customer_relations/show_exam_stu_detail', { params: params })
}
export const customer_relations_check = (params) => {
    return http.get('customer_relations/check', { params: params })
}
export const customer_relations_correct_center = (params) => {
    return http.get('customer_relations/correct_center', { params: params })
}
export const customer_relations_get_standard = (params) => {
    return http.get('customer_relations/get_standard', { params: params })
}
export const customer_relations_save_correct_score = (params) => {
    return http.get('customer_relations/save_correct_score', { params: params })
}
export const customer_relations_show_stu_exam_detail = (params) => {
    return http.get('customer_relations/show_stu_exam_detail', { params: params })
}
export const tranPurchaseMarketData = (params) => {
    return http.get('tranPurchase/marketData', { params: params })
}
export const tranPurchaseBrandTop = (params) => {
    return http.get('tranPurchase/brandTop', { params: params })
}
export const tranPurchaseDealTop = (params) => {
    return http.get('tranPurchase/dealTop', { params: params })
}
export const tranPurchaseBrandTopDetail = (params) => {
    return http.get('tranPurchase/brandTopDetail', { params: params })
}
export const tranPurchaseDealTopDetail = (params) => {
    return http.get('tranPurchase/dealTopDetail', { params: params })
}
export const tranPurchaseBalanceTopDetail = (params) => {
    return http.get('tranPurchase/balanceGoodsDetail', { params: params })
}
export const collectionTrainDetail = (params) => {
    return http.get('collection/trainDetail', { params: params })
}
export const operationsGetTrain = (params) => {
    return http.post('operations/getTrain', params)
}
export const teacheropmTrainingLogData = (params) => {
    return http.get('teacheropm/trainingLogData', { params: params })
}
export const baseDataAnalysisSaveDataAnalysis = (params) => {
    return http.post('baseDataAnalysis/saveDataAnalysis', params)
}
export const teacheropmExamData = (params) => {
    return http.get('teacheropm/examData', { params: params })
}
export const baseDataAnalysisStuDataAnalysis = (params) => {
    return http.get('baseDataAnalysis/stuDataAnalysis', { params: params })
}
export const baseDataAnalysisStuDataAnalysisSave = (params) => {
    return http.post('baseDataAnalysis/stuDataAnalysisSave', params)
}
export const collectionTeachGetAnswer = (params) => {
    return http.post('collection/teachGetAnswer', params)
}
export const examModules = (params) => {
    return http.get('exam/modules/' + params)
}
export const transactionTeaDetail = (params) => {
    return http.get('transaction/teaDetail', { params: params })
}
export const transactionTeaExamDetail = (params) => {
    return http.get('transaction/teaExamDetail', { params: params })
}
export const teacheropmJudgesCommercel = (params) => {
    return http.get('teacheropm/judgesCommercel', { params: params })
}
export const teacheropmGetJudgesStandard = (params) => {
    return http.get('teacheropm/getJudgesStandard', { params: params })
}
export const teacheropmScoreCommercel = (params) => {
    return http.post('teacheropm/scoreCommercel', params)
}
export const teacheropmReCorrecting = (params) => {
    return http.post('teacheropm/reCorrecting', params)
}
export const tranPurchaseGoodsClose = (params) => {
    return http.get('tranPurchase/goodsClose', { params: params })
}
export const baseDataAnalysisTeaDetail = (params) => {
    return http.get('baseDataAnalysis/teaDetail', { params: params })
}
export const baseDataAnalysisTeaExamDetail = (params) => {
    return http.get('baseDataAnalysis/teaExamDetail', { params: params })
}
export const pricing_managementSetting = (params) => {
    return http.post('pricing_management/setting', params)
}
export const pricing_managementGetPricing = (params) => {
    return http.post('pricing_management/getPricing', params)
}
export const operationsStuOperations = (params) => {
    return http.post('operations/stuOperations', params)
}
export const operationsStuEaxmOperations = (params) => {
    return http.post('operations/stuEaxmOperations', params)
}
export const operationsDelStuOperations = (params) => {
    return http.post('operations/delStuOperations', params)
}
export const teacheropStoreVisualDesignScore = (params) => {
    return http.post('teacherop/storeVisualDesignScore', params)
}
export const teacheropStoreVisualDesignList = (params) => {
    return http.get('teacherop/storeVisualDesignList', { params: params })
}
export const pricing_managementGetStuPricing = (params) => {
    return http.post('pricing_management/getStuPricing', params)
}
export const pricing_managementHotGoods = (params) => {
    return http.post('pricing_management/hotGoods', params)
}
export const pricing_managementStuEaxmPricing = (params) => {
    return http.post('pricing_management/stuEaxmPricing', params)
}
export const pricing_managementStuPricing = (params) => {
    return http.post('pricing_management/stuPricing', params)
}
export const tranPurchaseTeaExamDetail = (params) => {
    return http.get('tranPurchase/teaExamDetail', { params: params })
}
export const groupPromotionStuEaxmGroup = (params) => {
    return http.post('grouppromotion/stuEaxmGroup', params)
}
export const teacheropmJudgesSocial = (params) => {
    return http.get('teacheropm/judgesSocial', { params: params })
}
export const teacheropStoreVisualDesignDetail = (params) => {
    return http.get('teacherop/storeVisualDesignDetail', { params: params })
}
export const teacheropmScore = (params) => {
    return http.post('teacheropm/score', params)
}
export const teacheropmReScoreSocial = (params) => {
    return http.post('teacheropm/reScoreSocial', params)
}
export const teacheropmGetSocialInfo = (id) => {
    return http.get('teacheropm/getSocialInfo/' + id)
}
export const tranDistributionAdminDistributionCreate = (params) => {
    return http.post('tranDistribution/adminDistributionCreate', params)
}
export const pricing_managementTrainDetail = (params) => {
    return http.get('pricing_management/trainDetail', { params: params })
}
export const pricing_managementTeachGetAnswer = (params) => {
    return http.post('pricing_management/teachGetAnswer', params)
}
export const grouppromotionStuGroup = (params) => {
    return http.post('grouppromotion/stuGroup', params)
}
export const groupPromotionGetGroupInfo = (params) => {
    return http.post('grouppromotion/getGroupInfo', params)
}
export const grouppromotionTrainDetail = (params) => {
    return http.get('grouppromotion/trainDetail', { params: params })
}
export const groupPromotionGetStuGroup = (params) => {
    return http.post('grouppromotion/getStuGroup', params)
}
export const grouppromotionTeachGetAnswer = (params) => {
    return http.post('grouppromotion/teachGetAnswer', params)
}
export const tranDistributionStuShipAddress = (params) => {
    return http.post('tranDistribution/stuShipAddress', params)
}
export const tranDistributionStuShipAddressList = (params) => {
    return http.get('tranDistribution/stuShipAddressList', { params: params })
}
export const tranDistributionStuShipAddressDel = (params) => {
    return http.get('tranDistribution/stuShipAddressDel', { params: params })
}
export const tranDistributionStuSalesAddressList = (params) => {
    return http.get('tranDistribution/stuSalesAddressList', { params: params })
}
export const tranDistributionStuSalesAddress = (params) => {
    return http.post('tranDistribution/stuSalesAddress', params)
}
export const tranDistributionStuAfterSalesAddress = (params) => {
    return http.post('tranDistribution/stuAfterSalesAddress', params)
}
export const tranDistributioStuAfterSalesAddressList = (params) => {
    return http.get('tranDistribution/stuAfterSalesAddressList', { params: params })
}
export const tranDistributionStuAfterSalesAddressDel = (params) => {
    return http.get('tranDistribution/stuAfterSalesAddressDel', { params: params })
}
export const tranDistributionStuFreightTemplateSave = (params) => {
    return http.post('tranDistribution/stuFreightTemplateSave', params)
}
export const tranDistributionStuFreightTemplateList = (params) => {
    return http.get('tranDistribution/stuFreightTemplateList', { params: params })
}
export const tranDistributionStuFreightTemplateDel = (params) => {
    return http.post('tranDistribution/stuFreightTemplateDel', params)
}
export const grouppromotionGetTrainInfo = (params) => {
    return http.post('grouppromotion/getTrainInfo', params)
}
export const operationsTrainDetail = (params) => {
    return http.get('operations/trainDetail', { params: params })
}
export const operationsTeachGetAnswer = (params) => {
    return http.post('operations/teachGetAnswer', params)
}
export const tranDistributionTeaExamDetail = (params) => {
    return http.get('tranDistribution/teaExamDetail', { params: params })
}
export const checkCenter_monthForExamRoom = (params) => {
    return http.get('checkCenter/monthForExamRoom', { params: params })
}
export const checkCenter_dataCenter = (params) => {
    return http.get('checkCenter/dataCenter', { params: params })
}
export const endExam = (params) => {
    return http.post('examination/end', params)
}
export const operateActualPaper = (params) => {
    return http.post("checkCenter/optAddExam", params)
}
export const checkCenter_distributionOpt = (params) => {
    return http.get('checkCenter/distributionOpt', { params: params })
}
export const downloadIndustryData = () => {
    return http.get('buyer/industrydownload');
}
export const importIndustryData = (formdata) => {
    return http.post('buyer/import', formdata);
}
export const downloadBuyerData = () => {
    return http.get('buyer/download');
}
export const importBuyerData = (formdata) => {
    return http.post('buyer/importbuyer', formdata);
}
export const importExamData = (formdata) => {
    return http.post('apply/import', formdata)
}
export const getExamAll = (params) => {
    return http.get('apply_message/all', { params: params })
}
export const operationProblemSaveDzAndPoster = (params) => {
    return http.post('operationProblem/saveDzAndPoster', params)
}
export const stuOpSaveDzAndPposter = (params) => {
    return http.post('stuOp/saveDzAndPposter', params)
}
export const stuOpDzPosterList = (params) => {
    return http.get('stuOp/dzPosterList', { params: params })
}
export const stuOpDzPosterUploadImg = (params) => {
    return http.post('stuOp/dzPosterUploadImg', params)
}

export const  createArticle = (params) => {
    return http.get('stuOp/createArticle', {params: params})
}
export const teacheropDzPosterList = (params) => {
        return http.get('teacherop/dzPosterList', { params: params })
    }
    //四级文字撰写修改--学生保存修改答案
export const goodsTextWriteStuSaveAnswer = (params) => {
        return http.post('api/goodsTextWrite/stuSaveAnswer', params)
    }
    //四级文字撰写修改--学生获取答题详细
export const goodsTextWriteStuGetAnswer = () => {
    return http.get('api/goodsTextWrite/stuGetAnswer')
}
export const teacheropDzPosterScore = (params) => {
    return http.post('teacherop/dzPosterScore', params)
}
export const teacheropDzPosterData = (params) => {
    return http.get('teacherop/dzPosterData', { params: params })
}
export const goodsTextWriteRemoveStuAnswer = () => {
    return http.get('api/goodsTextWrite/removeStuAnswer')

}
export const stuOpStoreClassList = () => {
    return http.get('stuOp/storeClassList')
}
export const stuOpStoreClassSave = (params) => {
    return http.post('stuOp/storeClassSave', params)
}
export const stuOpDelStoreClass = (params) => {
    return http.get('stuOp/delStoreClass', {params: params})
}
export const stuOpDelStoreAllClass = () => {
    return http.get('stuOp/delStoreAllClass')
}
export const stuOpUploadGoodsDetImg = (params) => {
    return http.post('stuOp/uploadGoodsDetImg', params)
}
export const stuOpUploadGoodsImg = (params) => {
    return http.post('stuOp/uploadGoodsImg', params)
}
export const stuOpStuSaveGoods = (params) => {
    return http.post('stuOp/stuSaveGoods', params)
}
export const stuOpStuGoodsList = () => {
    return http.get('stuOp/stuGoodsList')
}
export const stuOpStuEditGoods = (params) => {
    return http.get('stuOp/stuEditGoods', {params: params})
}
export const stuOpGoodsInfo = (params) => {
    return http.get('stuOp/goodsInfo', {params: params})
}
export const stuKefuExamDetail = (params) => {
    return http.get('exam/stuKefuExamDetail', {params: params})
}
export const teacheropShopDecorationList = (params) => {
    return http.get('teacherop/shopDecorationList', {params: params})
}
export const teacheropShopDecorationStuDet = (params) => {
    return http.get('teacherop/shopDecorationStuDet', {params: params})
}
// export const teacheropShopDecorationData = (params) => {
//     return http.get('teacherop/shopDecorationData', {params: params})
// }
export const teacheropShopDecorationData = (params) => {
    return http.get('teacherop/dzPosterScoreDetails', {params: params})
}
export const ecommerceDataReportAdminSave = (params) => {
    return http.post('ecommerceDataReport/adminSave', params)
}
export const ecommerceDataReportImportStatisticalData = (params) => {
    return http.post('ecommerceDataReport/importStatisticalData', params)
}
export const ecommerceDataReportUploadAnalysisData = (params) => {
    return http.post('ecommerceDataReport/importStatisticalData', params)
}
export const ecommerceDataReportExportStatisticalData = (params) => {
    return http.get('ecommerceDataReport/exportStatisticalData', {params: params})
}
export const stuEcommerceDataReportSave = (params) => {
    return http.post('stuEcommerceDataReport/save', params)
}
export const stuEcommerceDataReportInfo = (params) => {
    return http.get('stuEcommerceDataReport/info', {params: params})
}
export const stuEcommerceDataReportGetStatisticalData = (params) => {
    return http.get('stuEcommerceDataReport/getStatisticalData', {params: params})
}
export const ecommerceDataReportExamDetail = (params) => {
    return http.get('ecommerceDataReport/examDetail', {params: params})
}
export const grouppromotionGetGroupMsg = (params) => {
    return http.get('grouppromotion/getGroupMsg', {params: params})
}
export const grouppromotionSaveGroupMsg = (params) => {
    return http.post('grouppromotion/saveGroupMsg', params)
}
export const stuOpGetOrderComment = (params) => {
    return http.get('stuOp/getOrderComment', {params: params})
}
export const stuOpSaveOrderComment = (params) => {
    return http.post('stuOp/saveOrderComment', params)
}
export const teacheropOrderCommentDetail=(params)=>{
    return http.get('teacherop/orderCommentDetail', {params: params})
}
export const StuOpProPeopleData = (params) => {
    return http.post('StuOpPro/peopleData', params)
}
export const StuOpProResourcesData = (params) => {
    return http.post('StuOpPro/resourcesData', params)
}
export const StuOpProResourcesAndPeopleList = (params) => {
    return http.post('StuOpPro/resourcesAndPeopleList', params)
}
export const getFansArea = () => {
    return http.get('operationProblem/postion_area_List')
}